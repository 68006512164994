import React, {  useEffect, useState } from 'react';
import Header from '../components/Header/Header';
import { Col, Container, Row } from 'react-bootstrap';
import FooterDark from '../components/Footer/FooterDark';
import FontFilmaker  from '../assets/font-filmaker.png';
import ReactPlayer from 'react-player';
import Vmeo from '../components/Vimeohook/Vmeo';
import Filmake from '../assets/PHOTOGRAPHY.png'
import Slider from '../components/Slider/Slider';




const Sobre = () => {

const[playVideo,setPlayVideo] = useState(true);
const[selecUrl, setSelectUrl] = useState('');
const[son, setSon] = useState(true);
const[son2, setSon2] = useState(true);


  const VideosPromo = [
    {
      id: 1,
      title: 'AMONAFANY FASHION FILM',
      describe: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna",
      url: '903795868',
      thumb: "https://i.vimeocdn.com/video/1784150714-d4d5149d741b0c58cd22f3dd5089dad6906128deb15fb7d64f285b2d62549dfb-d?mw=1500&mh=844&q=70",
      type: 'fashion',
      effect: 'none',
      format: 'hd',
    },
    {
      id: 2,
      title: 'PA CONCEPT FASHION FILM - JULHO',
      describe: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna",
      url: '904102381',
      thumb: "https://i.vimeocdn.com/video/1783498408-5685c31c20a3cf50c9d921a867ff204da7f1f2097457adf082a516213e8868b3-d?mw=5000&mh=2813&q=70",
      type: 'fashion',
      effect: 'none',
      format: 'sd',
    },
    {
    id: 3,
    title: 'Edge Production Talgui - Cosmopolitan',
    describe: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna",
    url: '904096775',
    thumb: "https://i.vimeocdn.com/video/1626696791-8c39eaa131b6fd698f9d32abd5ef5bbdd3b9ea3b36f9a455daa11e01cace1278-d?mw=1500&mh=844&q=70",
    type: 'fashion',
    effect: 'none',
    format: 'sd',
    },
{
    id: 4,
    title: 'Titulo 4',
    describe: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna",
    url: '903853638',
    thumb: "https://i.vimeocdn.com/video/1784138836-98e80246e547c5f78911f38ad6c610bbfff2993d13e08360808c38f67698c89f-d?mw=1500&mh=844&q=70",
    type: 'fashion',
    effect: 'none',
    format: 'sd',
    }
  ];



  return (
    <div className="fluid">
      <div style={{ marginBottom: '150px'}}>
         <Header />
      </div>
      {/* https://vimeo.com/903851449 */}
        <Row>
             <Col  className='text-center '>
             
              <div className='video-scholl'>
              <ReactPlayer 
               className='video-scholl-r'
              url={`https://vimeo.com/934997073`}
              width={'840px'}          
              height={'475px'}    
              playing={playVideo}
                muted={son}
                        loop
                        />
              </div>
       
             </Col>
        </Row>
        <Row>
            <Col  className='text-center'>
            <div className='title-school'>
                <h1 className='title-school-h4'>EDGE PRODUCTION</h1>
                        <p>Conheça a História por Trás da EDGE Production
        Na EDGE Production, nossa história é tão vibrante quanto nossos vídeos. 
        Fundada pela colaboração visionária de Roberto Kim e José Oh, combinamos 
        a experiência e a juventude para criar uma abordagem única no mundo da 
        produção audiovisual. Desde o início, buscamos desafiar as convenções e 
        trazer uma nova energia para o mercado. Saiba mais sobre nossa jornada, 
        nossa equipe e nossa missão de inspirar através do poder da imagem em movimento.
        </p>
             </div>
            </Col>
        </Row>
        <Row>
            <Col>
            <div  className='spacço-sobre'/>
            
            </Col>
        </Row>
        
      
     <FooterDark />
    </div>
    
  )

}
export default Sobre;

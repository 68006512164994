import React from "react";
import {Carousel} from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
// https://www.npmjs.com/package/react-responsive-carousel
import Img1 from '../../assets/slider/Artboard 1.jpg';
import Img2 from '../../assets/slider/Artboard 2.jpg';
import Img3 from '../../assets/slider/Artboard 3.jpg';
import Img4 from '../../assets/slider/Artboard 4.jpg';
import { Link } from "react-router-dom";
import { Alert } from "react-bootstrap";

const Slider4 = () =>{
  
        return (
            <Carousel showThumbs={false} transitionTime={200} showArrows={true} showStatus={true} autoPlay={true} infiniteLoop={true}>
            <div className="div-carrosel" onClick={() => window.location.href = '/portifolio'}>
              <img src={Img1} alt="img" width={'100%'}  />
              <p className="legend">Portfólio</p>
            </div>
            
            <div className="div-carrosel" onClick={() => window.location.href = '/producao'}>
              <img src={Img2} alt="img" width={'100%'}  />
              <p className="legend">Production</p>
            </div>

            <div  className="div-carrosel" onClick={() => window.location.href = '/fashion'}>
              <img src={Img3} alt="img" width={'100%'}  />
              <p className="legend">Fashion</p>
            </div>

            <div className="div-carrosel" onClick={() => window.location.href = '/sobre'}>
              <img src={Img4} alt="img" width={'100%'}  />
              <p className="legend">Sobre nós</p>
            </div>
          </Carousel>
        );
};

export default Slider4 ;
// Don't forget to include the css in your page 
// <link rel="stylesheet" href="carousel.css"/>
// Begin DemoSliderControls
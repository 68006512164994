import React from 'react';
import Header from '../components/Header/Header';
import { Col, Row } from 'react-bootstrap';
import FooterDark from '../components/Footer/FooterDark';
import ImgContato from '../assets/img-contato.png';
import Contact from '../components/Contact.js/Contact';
import {motion} from 'framer-motion';
import { Link } from 'react-router-dom';
import LogoWhatsapp from '../assets/logowhatsapp.png'

const Contato = () => {
 

  return (
    <div className="container">
      <div style={{ marginBottom: '150px'}}>
         <Header />
      </div>
     
      <Row>
          <Col md={7} className='text-center'>
              <motion.div
                  initial={{ opacity: 0, translateX: -210 }}
                          animate={{ opacity: 1, translateX: 0  }}
                          transition={{
                            duration: 3.8,
                            delay: 0.5,
                            ease: [0, 0.71, 0.2, 1.01]
                          }}
              >
                <img src={ImgContato} alt='contato' width={'600px'} height={'auto'} />
                <p className='title-contato-3'>Pronto para começar seu próximo projeto audiovisual? Entre 
                em contato conosco hoje mesmo para discutir suas ideias e objetivos. 
                Nossa equipe dedicada está aqui para ajudar você em cada etapa do processo, 
                desde a concepção até a entrega final. Junte-se à família EDGE Production e 
                deixe-nos ajudá-lo a contar sua história de forma única e memorável.</p>
              </motion.div>
          </Col>
           
          <Col >
            <motion.div className='title-contato' 
             initial={{ opacity: 0, translateX: 110 }}
             animate={{ opacity: 1, translateX: 0  }}
             transition={{
               duration: 3.8,
               delay: 0.5,
               ease: [0, 0.71, 0.2, 1.01]
             }}
            >
              
              <h3 id='title-contato-1'>Fale Conosco e Transforme </h3>
              <h1 id='title-contato-2'>sua Visão em Realidade</h1>
             
            </motion.div>
            <motion.div className='form-script'
               initial={{ opacity: 0, translateX: 110 }}
               animate={{ opacity: 1, translateX: 0  }}
               transition={{
                 duration: 3.8,
                 delay: 0.5,
                 ease: [0, 0.71, 0.2, 1.01]
               }}
            >


                 <div>
                  
                    <Link to={'https://api.whatsapp.com/send/?phone=11999316480&text=Concorrer.&type=phone_number&app_absent=0'}>
                     <button className='btn-f' >
                      <img src={LogoWhatsapp} alt='logo whatsapp' width={'50px'} style={{ padding: '10px'}}/>
                           Faça um orçamento
                     </button>
                    </Link>
                    <p style={{ color: "#fff", padding: "20px  "}}>PROJETOS A PARTIR DE R$5.000</p>
                 </div>
     
         
                {/* <Contact /> */}
            </motion.div>
          </Col>
      </Row>
     <FooterDark />
    </div>
    
  )

}
export default Contato;

import React, {  useEffect, useState } from 'react';
import Header from '../components/Header/Header';
import { Col, Container, Row } from 'react-bootstrap';
import FooterDark from '../components/Footer/FooterDark';
import FontFilmaker  from '../assets/font-filmaker.png';
import ReactPlayer from 'react-player';
import Vmeo from '../components/Vimeohook/Vmeo';
import Filmake from '../assets/PHOTOGRAPHY.png'
import Slider from '../components/Slider/Slider';




const School = () => {

const[playVideo,setPlayVideo] = useState(true);
const[selecUrl, setSelectUrl] = useState('');
const[son, setSon] = useState(false);
const[son2, setSon2] = useState(true);


  const VideosPromo = [
    {
      id: 1,
      title: 'AMONAFANY FASHION FILM',
      describe: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna",
      url: '903795868',
      thumb: "https://i.vimeocdn.com/video/1784150714-d4d5149d741b0c58cd22f3dd5089dad6906128deb15fb7d64f285b2d62549dfb-d?mw=1500&mh=844&q=70",
      type: 'fashion',
      effect: 'none',
      format: 'hd',
    },
    {
      id: 2,
      title: 'PA CONCEPT FASHION FILM - JULHO',
      describe: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna",
      url: '904102381',
      thumb: "https://i.vimeocdn.com/video/1783498408-5685c31c20a3cf50c9d921a867ff204da7f1f2097457adf082a516213e8868b3-d?mw=5000&mh=2813&q=70",
      type: 'fashion',
      effect: 'none',
      format: 'sd',
    },
    {
    id: 3,
    title: 'Edge Production Talgui - Cosmopolitan',
    describe: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna",
    url: '904096775',
    thumb: "https://i.vimeocdn.com/video/1626696791-8c39eaa131b6fd698f9d32abd5ef5bbdd3b9ea3b36f9a455daa11e01cace1278-d?mw=1500&mh=844&q=70",
    type: 'fashion',
    effect: 'none',
    format: 'sd',
    },
{
    id: 4,
    title: 'Titulo 4',
    describe: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna",
    url: '903853638',
    thumb: "https://i.vimeocdn.com/video/1784138836-98e80246e547c5f78911f38ad6c610bbfff2993d13e08360808c38f67698c89f-d?mw=1500&mh=844&q=70",
    type: 'fashion',
    effect: 'none',
    format: 'sd',
    }
  ];



  return (
    <div className="fluid">
      <div style={{ marginBottom: '150px'}}>
         <Header />
      </div>
      {/* https://vimeo.com/903851449 */}
        <Row>
             <Col  className='text-center '>
             
              <div className='video-scholl'>
              <ReactPlayer 
               className='video-scholl-r'
              url={`https://vimeo.com/903851449`}
              width={'100%'}
              height={'100%'}
                        playing={playVideo}
                        muted={son}
                        loop
                        />
              </div>
             <div className='title-school'>
                <h1 className='title-school-h1'>EDGE SCHOOL</h1>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
                  sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                  Quis ipsum suspendisse ultrices gravida. 
                  Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
             </div>
             </Col>
        </Row>
        <Container   className='bod-container-school'>
             <Row className='text-center'>
              <Col></Col>
              <Col className='text-center'>
                   <div className='text-center'>
                     <img src={FontFilmaker}width={'450px'} alt='font filmaker'/>
                  </div>
              </Col>
              <Col></Col>
             </Row>
             <Row>
                <Col className='text-center'>
                <div className="text-padding-scholl">
                  <p className='text-filmaker'>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do 
                  eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse 
                  ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. 
                  </p>
                   <button id='btn-1'>
                    Quero me matricular
                   </button>
                </div>
                </Col>
                <Col className='text-center'>
                   <div className='video2-school-div'>
                      <ReactPlayer
                          className='video2-school-iframe'
                          url={'https://vimeo.com/934997073'}
                          width={'600px'} 
                          onPlay={true}
                          playing={playVideo}
                          muted={son2}
                          loop
                          />
                                          
                   </div>
                
                </Col>
             </Row>

             <Row>
              { VideosPromo.map((item)=>(
              <Col  className=''>
                <div className='vimeo-scholl-promo-1' id={item.id}>
           
                       { playVideo && <Vmeo  url={item.url} muted={son2}/> }
              
                 </div>
                </Col>
              ))}


                
               
             </Row>
             <Row>
                <Col className='text-center'>
                  <div style={{ paddingTop: '120px'}}>
                    <img src={Filmake} alt='Filmaking' width={'450px'} height={'100%'}/>
                  </div>
                </Col>
             </Row>
             <Row>
             <Col className='text-center'>
                   <div className='video2-school-div'>
                      <ReactPlayer
                          className='video2-school-iframe'
                          url={'https://vimeo.com/934997073'}
                          width={'600px'} 
                          onPlay={true}
                          playing={playVideo}
                          muted={son2}
                          loop
                          />
                                          
                   </div>
                
                </Col>
                <Col className='text-center'>
                <div className="text-padding-scholl">
                  <p className='text-filmaker'>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do 
                  eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse 
                  ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. 
                  </p>
                   <button id='btn-1'>
                    Quero me matricular
                   </button>
                </div>
                </Col>
              
             </Row>

             <Row>
             <Col className='text-center'>
                <div className="text-padding-scholl">
                  <p className='text-filmaker'>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
                  sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                  Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan 
                  lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do 
                  eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices 
                  gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit 
                  amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna 
                  aliqua. 
                  </p>
                  
                </div>
                </Col>
             <Col className='text-center'>
                   <div className='video2-school-div'>
                      <Slider />
                   </div>
                
                </Col>
               
              
             </Row>
        </Container>
     <FooterDark />
    </div>
    
  )

}
export default School;

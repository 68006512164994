import React from "react";
import { Container, Row, Col} from "react-bootstrap";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Instagram from '@mui/icons-material/Instagram';
import Twitter from '@mui/icons-material/X';
import Vimeo from '../../assets/Ativo 1vimeo.png';
import { Link } from "react-router-dom";
// import Newslatter from "../Newslatter/Newslatter";

const Footer = ()=>{
    return(
        <Container fluid className="footer-bg">
            {/* <Row>
              <Col>
              </Col>
              <Col>
                <div className="text-center text-news">
                    <h1 id="newsletter-tittle">NEWSLETTER</h1>
                    <p>Faça seu cadastro para ficar por dentro das novidades</p>
                </div>
              
              </Col>
               <Col>
                  <div>
                     <Newslatter/>
                  </div>
              </Col>
              <Col>
              </Col>
            </Row> */}
            <Row >
                <Col>
                </Col>

                <Col>
                 <div className="section-social-itens">

                    <h2 className="social-title">SIGA A GENTE</h2>
                  <div className="social-icons">
                    <Link to={"https://www.instagram.com/edge.production_/"} className="links-1">
                        <Instagram className="icon" fontSize="134px" />
                    </Link>
                    <Link to={"#"} className="links-1">
                        <Twitter className="icon" fontSize="130px"/>
                    </Link >

                    <Link to={"https://vimeo.com/edgeproductionbr"} className="links-1">
                        <img src={Vimeo}  alt="Vimeo" width={'30px'}/>
                    </Link>

                    <Link to={"https://www.linkedin.com/company/edgeproduction/posts/?feedView=all"} className="links-1">
                        <LinkedInIcon className="icon" fontSize="134px"/>
                    </Link>
                  </div>
                 </div>
                </Col>
                
                <Col>
                     <div className="subtitle-end">
                        <p>
                            Rua José Paulino, 795, 2nd and. Bom Retiro - São Paulo
                            CEP. 01125-001

                            +55 11 95769-5733
                            www.edgeproduction.com.br
                        </p>
                       
                     </div>
                </Col>

                <Col>
                </Col>

           </Row>
        </Container>
    )
}

export default Footer;
import React from 'react';
// import Vimeo from 'react-vimeo';
import Vimeo from '@u-wave/react-vimeo';
import {motion} from 'framer-motion';

const Vimeohook = ({url, onClick, width,  height, autoplay, TitleVideo}) => {
  const idDoVideoVimeo = `http://vimeo.com/${url}`;

  return (
    <motion.div
    initial={{ opacity: 0, scale: 0 }}
    animate={{ opacity: 1, scale: 1 }}
    transition={{
      duration: 2.8,
      delay: 0.5,
      ease: [0, 0.71, 0.2, 1.01]
    }}  
    className='vimeo-hook-position' onClick={onClick}>
      <Vimeo
      className='vimeo-container'
        video={idDoVideoVimeo}
        loop
        autoplay={autoplay}
        onPlay={false}
        controls={false}
        muted={false}
        showPortrait
        // width={width}
        height={'500px'}
       
      />
         <motion.div 
         initial={{ opacity: 0, translateY: -100 }}
         animate={{ opacity: 1, translateY: 1 }}
         transition={{
           duration: 2.8,
           delay: 3,
           ease: [0, 0.71, 0.2, 1.01]
         }}  
         className='video-description'>
                <h5 className='title-card-show'>{TitleVideo}</h5>
               
          </motion.div>
    </motion.div>
    
  );
};

export default Vimeohook;

// import React from 'react'
// import ReactDOM from 'react-dom'
// import ReactPlayer from 'react-player'
// import { Player } from 'video-react'
// import Vimeo from '@u-wave/react-vimeo'
// import YouTube from '@u-wave/react-youtube'

// import './styles.css'

// function App() {
//   return (
//     <div className="App">
//       <h1>Hello CodeSandbox</h1>
//       <h2>VImeo</h2>
//       <Vimeo
//         video="https://vimeo.com/226260195"
//         autoplay={false}
//         loop
//         muted={false}
//         showPortrait
//         background={true}
//         controls={false}
//       />

//       <h2>Youtube</h2>
//       <YouTube video="Bcm8tkZDxXM" autoplay />

//       <h2>ReactPlayer</h2>
//       <div style={{ height: '100vh' }}>
//         <ReactPlayer
//           url="https://www.youtube.com/watch?v=rnwlWn603g4"
//           className="react-player"
//           playing={false}
//           width="100%"
//           height="100%"
//         />
//       </div>
//       <Player style={{ width: '500px' }}>
//         <source src="https://media.w3.org/2010/05/sintel/trailer_hd.mp4" />
//       </Player>
//     </div>
//   )
// }

// const rootElement = document.getElementById('root')
// ReactDOM.render(<App />, rootElement)


import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player/vimeo';


const Vmeo = ({url, muted}) => {
 
  const [isPlaying, setIsPlaying] = useState(false);

  // const handlePlayPause = () => {
  //   setIsPlaying(!isPlaying);
  // };
 useEffect(()=>{
    setIsPlaying(true);
 },[])

  return (
    <div className='vmeo'>
      <ReactPlayer
       url={`https://vimeo.com/${url}`}
       width={'280px'}
       onPlay={true}
       playing={isPlaying}
       muted={muted}
       loop
      />
     
    </div>
  );
};

export default Vmeo;

import React, { useState, useEffect } from 'react';

const useResizeHeaderHook = () => {
  const [showHeader, setShowHeader] = useState(true); // Initial header

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      const breakpointWidth = 780; // Change this to your desired breakpoint

      if (windowWidth <= breakpointWidth) {
        setShowHeader(false);
      } else {
        setShowHeader(true);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return { showHeader };
};
export default useResizeHeaderHook;
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import HeaderMobile from "../components/HeaderMobile/HeaderMobile";
import FooterDark from "../components/Footer/FooterDark";
import VimeoPlayer from "react-player/vimeo";
import GraphicEqIcon from '@mui/icons-material/GraphicEq';
import Edge from '../assets/edge-aprodutora.png';
import Apple from  '../assets/logos/APPLE.png';
import CHCH from  '../assets/logos/CHCH.png';
import Cacaushow from  '../assets/logos/Cacaushow.png';
import kiss from  '../assets/logos/kiss.png';
import prime from  '../assets/logos/prime.png';
import nissan from  '../assets/logos/nissan.png';
import seara from  '../assets/logos/seara.png';
import benneton from  '../assets/logos/benneton.png';
import stanley from  '../assets/logos/stanley.png';
import disnep from  '../assets/logos/disnep.png';
import fast from  '../assets/logos/FAST.png';
import CocaCola from  '../assets/logos/cocacola.png';

 

const HomeMobile = ()=>{
const[mudo, setMudo]=useState(true);
const[mudo2, setMudo2]=useState(true);
const handleClick = () => {
    setMudo((prevState) => !prevState);
  };

  useEffect(()=>{},[mudo]);
    return(
        <div fluid>
            <Row>
                <div>
                    <HeaderMobile/>
                </div>
            </Row>
             <Row>
            <div className="video-banner-mobile">
                    <VimeoPlayer         
                        url={"https://vimeo.com/934997073"}          
                        playing={true}          
                        loop  
                        // onStart={(start)=>alert('video iniciado')}
                        onError={(err)=>console.log(err)}       
                        muted={mudo}       
                        width={'100%'}          
                        height={'220px'}         
                    />
            </div>

            <div className="playable-iframe-container-mobile" onClick={handleClick}>
                 <GraphicEqIcon fontSize="large" className="icon-style"/>
                 {mudo?   <p className="P-BTN">SOUND: OFF</p>: <p className="P-BTN">SOUND: ON</p>}
               
                {/* <iframe title="sounde"
                src="https://lottie.host/embed/3c3b4a08-ff72-4b10-924b-fad348d4d20b/sG4Y3M2Vd9.json" 
                width="600" height="400"></iframe> */}
             </div>
        </Row>

        <Row>
                    <Col className="text-center">
                        <div className="logo-edgeprodutora-mobile">
                           <img src={Edge} alt="edge produtora" width={'200px'}/>
                        </div>
                     
                    </Col>
        </Row>
        <Row>
                         <div className="video2-mobile">
                            <VimeoPlayer   
                               className="video2-mobile-home"      
                                url={"https://vimeo.com/903851449"}          
                                playing={true}          
                                loop  
                                // onStart={(start)=>alert('video iniciado')}
                                onError={(err)=>console.log(err)}       
                                muted={mudo2}       
                                width={'100%'} 
                                height={'220px'}         
                                
                        />
                        </div>
        </Row>
        <Row>
            <div>
                <div className="text-center text-video1-mobile">
                     <h1 id="textt-video2-mobile">Se da para imaginar. da pra criar.</h1>
                     <h4 style={{ color: "#fff", fontSize: '20px', padding: '0px 30px 0px 30px'}}>Bem-vindo à EDGE Production: Onde Criatividade e Profissionalismo Se Encontram!</h4>
                     <p style={{ color: "#fff", padding: '10px 30px'}}>Descubra o mundo vibrante da produção audiovisual com a EDGE Production. Somos 
                        uma equipe jovem e dinâmica, trazendo uma abordagem fresca e criativa para cada projeto. 
                        Seja parte da nossa jornada enquanto exploramos 
                        as últimas tendências e entregamos conteúdo de alta qualidade para marcas e públicos jovens.</p>
                </div>
            </div>
        </Row>
        <Row>
            <div>
                <div className="text-video1-mobile2">
                     <h1 id="class-marcas">Marcas que trabalhamos</h1>
                </div>
            </div>
        </Row>
        
        <Row>
                    <Col>
                        <div className="logos text-center">
                            <img src={kiss} alt="kiss" width="236px" height="91px"/>
                        </div>
                  
                   
                         <div className="logos text-center">
                             <img src={CocaCola} alt="CocaCola" width="236px" height="91px"/>
                        </div>
                    
                        <div className="logos text-center">
                             <img src={prime} alt="kiss" width="236px" height="91px"/>
                        </div>
                  
               
                  
                        <div className="logos text-center">
                            <img src={nissan} alt="nissan" width="236px" height="91px"/>
                        </div>
                   
                         <div className="logos text-center">
                             <img src={seara} alt="seara" width="236px" height="91px"/>
                        </div>
                    
                        <div className="logos text-center">
                             <img src={benneton} alt="kiss" width="236px" height="91px"/>
                        </div>
               

                
                        <div className="logos text-center">
                            <img src={stanley} alt="stanley" width="236px" height="91px"/>
                        </div>
                    
                         <div className="logos text-center">
                             <img src={disnep} alt="CocaCola" width="236px" height="91px"/>
                        </div>
                    
                        <div className="logos text-center">
                             <img src={Cacaushow} alt="kiss" width="236px" height="91px"/>
                        </div>
                    </Col>
                 
            </Row>
        <FooterDark/>
        </div>
    )
}

export default HomeMobile;
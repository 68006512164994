import React from "react";

const videos = [
    {
      id: 1,
      title: 'AMONAFANY FASHION FILM',
      describe: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna",
      url: '903851449',
      thumb: "https://i.vimeocdn.com/video/1784150714-d4d5149d741b0c58cd22f3dd5089dad6906128deb15fb7d64f285b2d62549dfb-d?mw=1500&mh=844&q=70",
      type: 'music',
      effect: 'none',
      format: 'hd',
    },
    {
      id: 2,
      title: 'PA CONCEPT FASHION FILM - JULHO',
      describe: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna",
      url: '903412590',
      thumb: "https://i.vimeocdn.com/video/1783498408-5685c31c20a3cf50c9d921a867ff204da7f1f2097457adf082a516213e8868b3-d?mw=5000&mh=2813&q=70",
      type: 'fashion',
      effect: 'none',
      format: 'sd',
    },
    {
    id: 3,
    title: 'Edge Production Talgui - Cosmopolitan',
    describe: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna",
    url: '805624395',
    thumb: "https://i.vimeocdn.com/video/1626696791-8c39eaa131b6fd698f9d32abd5ef5bbdd3b9ea3b36f9a455daa11e01cace1278-d?mw=1500&mh=844&q=70",
    type: 'fashion',
    effect: 'none',
    format: 'sd',
    },
{
    id: 4,
    title: 'Titulo 4',
    describe: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna",
    url: '903844118',
    thumb: "https://i.vimeocdn.com/video/1784138836-98e80246e547c5f78911f38ad6c610bbfff2993d13e08360808c38f67698c89f-d?mw=1500&mh=844&q=70",
    type: 'fashion',
    effect: 'none',
    format: 'sd',
    }
    ,
    {
      id: 5,
      title: 'AVERARA FASHION FILM - JUNHO',
      describe: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna",
      url: '903849448',
      thumb: "https://i.vimeocdn.com/video/1784146525-c8576f658711d9b0a6132e8373cab7ed2fafa4450726a0da16049e4c86836b74-d?mw=1500&mh=844&q=70",
      type: 'fashion',
      effect: 'none',
      format: 'sd',
      }
      ,
      {
        id: 6,
        title: 'AVERARA FASHION FILM - SUMMER',
        describe: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna",
        url: '903846819',
        thumb: "https://i.vimeocdn.com/video/1784142231-53adb594996eb4ee49012960af031752d9e5c09835fb862ea1a813ebfd7354fe-d?mw=1500&mh=844&q=70",
        type: 'fashion',
        effect: 'none',
        format: 'sd',
        },
     
          {
            id: 7,
            title: 'SIMPLE LIFE FASHION FILM - NIGHT',
            describe: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
            url: '903795868',
            thumb: "https://i.vimeocdn.com/video/1784069828-ae69458926e73b8a3e8d8b1b03550b8f5e5819edb01d68670e622ec77f56fbd4-d?mw=500&mh=888&q=70",
            type: 'fashion reals',
            effect: 'none',
            format: 'sd',
            },

            {
                id: 8,
                title: 'KARMANI FASHION REELS',
                describe: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                url: '903852854',
                thumb: "https://i.vimeocdn.com/video/1784151160-aa4771f077d63df90eeda80b941e18f2a94e25e53a44b088ed8fc5ae0202d921-d?mw=500&mh=888&q=70",
                type: 'fashion reals',
                effect: 'none',
                format: 'sd',
                },

                {
                    id: 9,
                    title: 'PA CONCEPT FAHSION REELS 1',
                    describe: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                    url: '903854748',
                    thumb: "https://i.vimeocdn.com/video/1784154404-5242709911381a43c07daaf1f49a3a15be8ed89cd2c85a9799c7c75adf59f1b2-d?mw=500&mh=888&q=70",
                    type: 'fashion reals',
                    effect: 'none',
                    format: 'sd',
                    },
           

                {
                    id: 10,
                    title: 'PA CONCEPT FASHION REELS - JULHO 2023',
                    describe: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                    url: '904103401',
                    thumb: "https://i.vimeocdn.com/video/1784538432-03eca2a98a5f828c55dd755e4c9bcf5e076896a00cb5fefd7290c8f865818349-d?mw=500&mh=888&q=70",
                    type: 'fashion reals',
                    effect: 'none',
                    format: 'sd',
                    },

                    {
                        id: 11,
                        title: 'PA CONCEPT FASHION REELS - AGOSTO 20233',
                        describe: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                        url: '904102381',
                        thumb: "https://i.vimeocdn.com/video/1784537277-71e5876920b43d2598e0b1048be3e083348a786fe637dbd18c36d0fc760e734a-d?mw=5000&mh=2813&q=70",
                        type: 'fashion reals',
                        effect: 'none',
                        format: 'sd',
                        },

                        {
                            id: 12,
                            title: 'PA CONCEPT FASHION REELS - AGOSTO 2023',
                            describe: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                            url: '904099740',
                            thumb: "https://i.vimeocdn.com/video/1784533450-d433229ae4c485d19dc0f17599683c5979cbe7f584a4862f72301b9b1a784beb-d?mw=500&mh=888&q=70",
                            type: 'fashion reals',
                            effect: 'none',
                            format: 'sd',
                            },

                            {
                                id: 13,
                                title: 'AVERARA FASHION REELS - JUNHO 2023',
                                describe: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                                url: '904098474',
                                thumb: "https://i.vimeocdn.com/video/1784532100-26a1c52fc7ad3c7f3f1285ec375c4855cdf9ee33b7ff238d661e2b5855d6365a-d?mw=500&mh=888&q=70",
                                type: 'fashion reals',
                                effect: 'none',
                                format: 'sd',
                                },
                                {
                                    id: 14,
                                    title: 'AMO BEM-ME-QUER FASHION REELS - SETEMBRO 2023',
                                    describe: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                                    url: '904096775',
                                    thumb: "https://i.vimeocdn.com/video/1784529283-7dea46d33cc28d5b4376bda39c5f134b891c2a2c0c4cc91f7d6eae4d9e79d666-d?mw=500&mh=888&q=70",
                                    type: 'fashion reals',
                                    effect: 'none',
                                    format: 'sd',
                                    },
                                    {
                                        id: 15,
                                        title: 'PA CONCEPT FASHION REELS 2',
                                        describe: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                                        url: '903855586',
                                        thumb: "https://i.vimeocdn.com/video/1784156558-39832a718addfdcb4101202ea0c57e8c75c0c622a5aa8e330e1aa22baf534363-d?mw=500&mh=888&q=70",
                                        type: 'fashion reals',
                                        effect: 'none',
                                        format: 'sd',
                                        },

                                      

                                            {
                                                id: 16,
                                                title: 'SIMPLE LIFE FASHION REELS - MAIO 2023',
                                                describe: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                                                url: '904105044',
                                                thumb: "https://i.vimeocdn.com/video/1784541413-6df5870532202cd15a932359caea1ea91a054f9c5c420412a237faa6eeccafb9-d?mw=500&mh=888&q=70",
                                                type: 'fashion reals',
                                                effect: 'none',
                                                format: 'sd',
                                                },


                                            {
                                                id: 17,
                                                title: 'OLIE FASHION REELS',
                                                describe: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                                                url: '903853638',
                                                thumb: "https://i.vimeocdn.com/video/1784152368-dea98dedf5634a755b0a62b86e477b02bac8be5b91ad89b3002b346f312c9cbc-d?mw=500&mh=888&q=70",
                                                type: 'fashion reals',
                                                effect: 'none',
                                                format: 'sd',
                                                },

                                        
                                                    {
                                                        id: 18,
                                                        title: 'SIMPLE LIFE FASHION FILM - MAIO',
                                                        describe: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                                                        url: '903794837',
                                                        thumb: "https://i.vimeocdn.com/video/1784068415-1168b1d3b67dc5c539adac41767d115f0344978009d95573ad87b7bca61220fb-d?mw=500&mh=888&q=70",
                                                        type: 'fashion reals',
                                                        effect: 'none',
                                                        format: 'sd',
                                                        },

                                                        {
                                                            id: 19,
                                                            title: 'THOMY WEST X SEARA - SEARA FRANGOS CROCANTES',
                                                            describe: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                                                            url: '903396850',
                                                            thumb: "https://i.vimeocdn.com/video/1783475445-39257e072455a5a7a9c30f63fa2e33fe88c46fb187ce77f033abe6af4b59ceb3-d?mw=500&amp;mh=888&amp;q=70",
                                                            type: 'publicidade',
                                                            effect: 'none',
                                                            format: 'sd',
                                                            },


                                                            {
                                                                id: 20,
                                                                title: 'TATI EMY X RUFFLES - VIVA ESSA ONDA',
                                                                describe: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                                                                url: '903392027',
                                                                thumb: "https://i.vimeocdn.com/video/1783468686-1c43e271476abd1410e23e81e1e70e19dff558e9cf2d9d60b6e089dc89b07950-d?mw=500&mh=888&q=70",
                                                                type: 'publicidade',
                                                                effect: 'none',
                                                                format: 'sd',
                                                                },

                                                                {
                                                                    id: 21,
                                                                    title: 'TATI EMY X PRIME VIDEO - UM ANO INESQUECÍVEL',
                                                                    describe: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                                                                    url: '903384119',
                                                                    thumb: "https://i.vimeocdn.com/video/1783457111-98a8ff0b2d26e47b124c323931e19161fc11d6d83d7b1ff54ca1c6645ce1892a-d?mw=500&mh=888&q=70",
                                                                    type: 'publicidade',
                                                                    effect: 'none',
                                                                    format: 'sd',
                                                                    },

                                                                    {
                                                                        id: 22,
                                                                        title: 'TATI EMY X PRIME VIDEO - UM ANO INESQUECÍVEL',
                                                                        describe: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                                                                        url: '903381224',
                                                                        thumb: "https://i.vimeocdn.com/video/1783452709-c210e8a5d9bb66520771ce146bbcd82e8837ce6f21334571798143e62243cb13-d?mw=500&mh=888&q=70",
                                                                        type: 'publicidade',
                                                                        effect: 'none',
                                                                        format: 'sd',
                                                                        },

                                                                        {
                                                                            id: 23,
                                                                            title: 'JESSIE SHEN X COUTINHO X CACAU SHOW - BITES',
                                                                            describe: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                                                                            url: '903378827',
                                                                            thumb: "https://i.vimeocdn.com/video/1783449508-2c4ff2e7f0c02876e338bd1df858f9dfbc63a2b85b31029ab8cb04b271defdb4-d?mw=500&mh=888&q=70",
                                                                            type: 'publicidade',
                                                                            effect: 'none',
                                                                            format: 'sd',
                                                                            },

                                                                            {
                                                                                id: 24,
                                                                                title: 'ALLAN JEON X BENETTON - TRIBE',
                                                                                describe: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                                                                                url: '903372584',
                                                                                thumb: "https://i.vimeocdn.com/video/1783440090-65bd6c4d1da9058d920bf022a770e35a9f300ddf6019edc1b1c7585a847a3308-d?mw=500&mh=888&q=70",
                                                                                type: 'publicidade',
                                                                                effect: 'none',
                                                                                format: 'sd',
                                                                                },
                                        
                                                                                {
                                                                                    id: 25,
                                                                                    title: 'YUMA ONO X NISSAN - NISSAN KICKS FESTIVAL DO JAPÃO',
                                                                                    describe: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                                                                                    url: '903365836',
                                                                                    thumb: "https://i.vimeocdn.com/video/1783430260-78bfea53681821562fc52d271705977bf82e4b886cf3c67eac89ad1987d20f3a-d?mw=500&mh=888&q=70",
                                                                                    type: 'publicidade',
                                                                                    effect: 'none',
                                                                                    format: 'sd',
                                                                                    },


                                                                                    {
                                                                                        id: 26,
                                                                                        title: 'Tati Emy X Prime Video - Um Ano Inesquecível',
                                                                                        describe: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                                                                                        url: '903337223',
                                                                                        thumb: "https://i.vimeocdn.com/video/1783389631-973337bd16b00c0563b030b1aa7fc2e8b4099f36840f500cae87288edee55e43-d?mw=500&mh=888&q=70",
                                                                                        type: 'publicidade',
                                                                                        effect: 'none',
                                                                                        format: 'sd',
                                                                                        },


                                                                                            {
                                                                                                id: 27,
                                                                                                title: '1117_PA_05',
                                                                                                describe: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                                                                                                url: '945849782',
                                                                                                thumb: "https://i.vimeocdn.com/video/1851508281-32cddabe7727559abad89c7e5d6bdab5cf020c72f4acc8be5cf8ed850e6534c3-d?mw=500&mh=888&q=70",
                                                                                                type: 'publicidade',
                                                                                                effect: 'none',
                                                                                                format: 'sd',
                                                                                                },


                                                                                                    {
                                                                                                        id: 28,
                                                                                                        title: '1106_UNIQUECHIC_EVENTO2',
                                                                                                        describe: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                                                                                                        url: '945849669',
                                                                                                        thumb: "https://i.vimeocdn.com/video/1851508136-ccdfae4843fe30804c3dc82ccba0d6f9d0c8baa8dd59af3468e146f521ed4627-d?mw=500&mh=888&q=70",
                                                                                                        type: 'publicidade',
                                                                                                        effect: 'none',
                                                                                                        format: 'sd',
                                                                                                        },

                                                                                                        
                                                                                                    {
                                                                                                        id: 29,
                                                                                                        title: '1106_UNIQUECHIC_EVENTO1',
                                                                                                        describe: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                                                                                                        url: '945849517',
                                                                                                        thumb: "https://i.vimeocdn.com/video/1851507977-05fc677104dd7ad592e915e2966fca91ba6db63d177a6cdea04a0fca724ee7db-d?mw=500&mh=888&q=70",
                                                                                                        type: 'publicidade',
                                                                                                        effect: 'none',
                                                                                                        format: 'sd',
                                                                                                        },


                                                                                                        {
                                                                                                            id: 30,
                                                                                                            title: '1005_TALGUI_V02',
                                                                                                            describe: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                                                                                                            url: '945849420',
                                                                                                            thumb: "https://i.vimeocdn.com/video/1851507773-a2e3202fdff372c1a664b885a81e09272702661ec9f290ec0da6ab70b1f82c7d-d?mw=500&mh=888&q=70",
                                                                                                            type: 'publicidade',
                                                                                                            effect: 'none',
                                                                                                            format: 'sd',
                                                                                                            },

                                                                                                            {
                                                                                                                id: 31,
                                                                                                                title: '1005_TALGUI_V02',
                                                                                                                describe: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                                                                                                                url: '945849273',
                                                                                                                thumb: "https://i.vimeocdn.com/video/1851507562-7e0d63acfbe55a94372295fe981b9bcfc81b2e5169fc6b175a04a56abc37a5b9-d?mw=500&mh=888&q=70",
                                                                                                                type: 'publicidade',
                                                                                                                effect: 'none',
                                                                                                                format: 'sd',
                                                                                                                },


                                                                                                                {
                                                                                                                    id: 32,
                                                                                                                    title: '0221_FASTSHOP_INTERVIEW02 v3',
                                                                                                                    describe: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                                                                                                                    url: '945848956',
                                                                                                                    thumb: "https://i.vimeocdn.com/video/1851507400-41ee24faea2093f7d990a39566a6d17b9b23fced9a3ac09dd5357f400b0ab42c-d?mw=500&mh=888&q=70",
                                                                                                                    type: 'publicidade',
                                                                                                                    effect: 'none',
                                                                                                                    format: 'sd',
                                                                                                                    },


                                                                                                                    {
                                                                                                                        id: 33,
                                                                                                                        title: 'STUDIOMANI_0603_00_2',
                                                                                                                        describe: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                                                                                                                        url: '944546380',
                                                                                                                        thumb: "https://i.vimeocdn.com/video/1849484995-188d9e42d78f892a9cd50e42bec754bf88a0fd09823d3b8e019d75288e9c8f7a-d?mw=500&mh=888&q=70",
                                                                                                                        type: 'publicidade',
                                                                                                                        effect: 'none',
                                                                                                                        format: 'sd',
                                                                                                                        },

                                                                                                                        {
                                                                                                                            id: 34,
                                                                                                                            title: 'LUISANCE_3D',
                                                                                                                            describe: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                                                                                                                            url: '944546332',
                                                                                                                            thumb: "https://i.vimeocdn.com/video/1849484720-f572001b7605b260fa0af07177047bcebc0c0677d1c68f06a7ea57f801be9f10-d?mw=500&mh=888&q=70",
                                                                                                                            type: 'publicidade',
                                                                                                                            effect: 'none',
                                                                                                                            format: 'sd',
                                                                                                                            },


                                                                                                                            {
                                                                                                                                id: 35,
                                                                                                                                title: 'HANOK_0506_02',
                                                                                                                                describe: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                                                                                                                                url: '944546266',
                                                                                                                                thumb: "https://i.vimeocdn.com/video/1849484661-7ff9354ff97ab4e8ac5fcaf47c8440361f8213af4e9390af4bce39f5c50d1c09-d?mw=500&mh=888&q=70",
                                                                                                                                type: 'publicidade',
                                                                                                                                effect: 'none',
                                                                                                                                format: 'sd',
                                                                                                                                },


                                                                                                                                {
                                                                                                                                    id: 36,
                                                                                                                                    title: '231206_JESSIE+CAROLHERRERA_V5_logo',
                                                                                                                                    describe: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                                                                                                                                    url: '944545674',
                                                                                                                                    thumb: "https://i.vimeocdn.com/video/1849484112-2b35f397bcf7ec9db27524b2688e0d6456055e1cae6641552c1e5ba9cc357c2f-d?mw=500&mh=888&q=70",
                                                                                                                                    type: 'publicidade',
                                                                                                                                    effect: 'none',
                                                                                                                                    format: 'sd',
                                                                                                                                    },


                                                                                                                                    {
                                                                                                                                        id: 37,
                                                                                                                                        title: '2102_JESSIESHEN_STANLEY',
                                                                                                                                        describe: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                                                                                                                                        url: '944545514',
                                                                                                                                        thumb: "https://i.vimeocdn.com/video/1849483619-769ffc271ef24818c8273eeaf0573700f0405614d55dd7755cc7228de33b0442-d?mw=500&mh=888&q=70",
                                                                                                                                        type: 'publicidade',
                                                                                                                                        effect: 'none',
                                                                                                                                        format: 'sd',
                                                                                                                                        },


                                                                                                                                        {
                                                                                                                                            id: 38,
                                                                                                                                            title: '1122_TATIEMY_RUFFLES_V2',
                                                                                                                                            describe: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                                                                                                                                            url: '944545255',
                                                                                                                                            thumb: "https://i.vimeocdn.com/video/1849483194-cf8afb1dcfd443ab8fa8290d4daa2c91e92079de4f420d00b5458888d6e27780-d?mw=500&mh=888&q=70",
                                                                                                                                            type: 'publicidade',
                                                                                                                                            effect: 'none',
                                                                                                                                            format: 'sd',
                                                                                                                                            },

                                                                                                                                            {
                                                                                                                                                id: 39,
                                                                                                                                                title: '1120_OTTUGUI_ADS03',
                                                                                                                                                describe: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                                                                                                                                                url: '944545179',
                                                                                                                                                thumb: "https://i.vimeocdn.com/video/1849482940-aebe98bca16d6e477650fca6982feb0b4a38fe91971ce92c1d82362a23c9cd60-d?mw=500&mh=888&q=70",
                                                                                                                                                type: 'publicidade',
                                                                                                                                                effect: 'none',
                                                                                                                                                format: 'sd',
                                                                                                                                                },


                                                                                                                                                {
                                                                                                                                                    id: 40,
                                                                                                                                                    title: '1014_DANIOH_IPHONE15',
                                                                                                                                                    describe: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                                                                                                                                                    url: '944545103',
                                                                                                                                                    thumb: "https://i.vimeocdn.com/video/1849482828-2a2eee49bd77b767bd59a686899422261900eda9d70f29d470e276c7d6886927-d?mw=500&mh=888&q=70",
                                                                                                                                                    type: 'publicidade',
                                                                                                                                                    effect: 'none',
                                                                                                                                                    format: 'sd',
                                                                                                                                                    },

                                                                                                                                                    {
                                                                                                                                                        id: 41,
                                                                                                                                                        title: '1006_KARINREITER_VID11',
                                                                                                                                                        describe: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                                                                                                                                                        url: '944544953',
                                                                                                                                                        thumb: "https://i.vimeocdn.com/video/1849483125-ea23805ad33e4c894ef6140f4ee6ad887775b996ca43439574d97b624fa4fd44-d?mw=500&mh=888&q=70",
                                                                                                                                                        type: 'publicidade',
                                                                                                                                                        effect: 'none',
                                                                                                                                                        format: 'sd',
                                                                                                                                                        },


                                                                                                                                                        {
                                                                                                                                                            id: 42,
                                                                                                                                                            title: '0808_ALLANJEON_PRIMEVIDEO_EDIT',
                                                                                                                                                            describe: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                                                                                                                                                            url: '944544455',
                                                                                                                                                            thumb: "https://i.vimeocdn.com/video/1849482123-3c705ad1755d398f1095c4639f815c981ec84ca9b99a5b4622a27a8daf961054-d?mw=500&mh=888&q=70",
                                                                                                                                                            type: 'publicidade',
                                                                                                                                                            effect: 'none',
                                                                                                                                                            format: 'sd',
                                                                                                                                                            },


                                                                                                                                                            {
                                                                                                                                                                id: 43,
                                                                                                                                                                title: '0502_JESSIESHEN_MELU',
                                                                                                                                                                describe: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                                                                                                                                                                url: '944544253',
                                                                                                                                                                thumb: "https://i.vimeocdn.com/video/1849481865-7eabf0ab50767e07831b3065f9e2e731d687ce71490f769023a4ebfc2b2efbc7-d?mw=500&mh=888&q=70",
                                                                                                                                                                type: 'publicidade',
                                                                                                                                                                effect: 'none',
                                                                                                                                                                format: 'sd',
                                                                                                                                                                },

                                                                                                                                                                {
                                                                                                                                                                    id: 44,
                                                                                                                                                                    title: '0418_CIABEAUTY_VID03',
                                                                                                                                                                    describe: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                                                                                                                                                                    url: '944544176',
                                                                                                                                                                    thumb: "https://i.vimeocdn.com/video/1849481555-33837aa8dedcf4f9943c32f48dc2a5745e46ceca4294a911f9d10741f9afb3ca-d?mw=500&mh=888&q=70",
                                                                                                                                                                    type: 'publicidade',
                                                                                                                                                                    effect: 'none',
                                                                                                                                                                    format: 'sd',
                                                                                                                                                                    },


                                                                                                                                                                    {
                                                                                                                                                                        id: 45,
                                                                                                                                                                        title: '0412_KSCOSM_12(A)',
                                                                                                                                                                        describe: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                                                                                                                                                                        url: '944544075',
                                                                                                                                                                        thumb: "https://i.vimeocdn.com/video/1849481505-23aa4e1bab2b013d63ce4a4828edc51ecddebaef81c7931b37462f1784a2be24-d?mw=500&mh=888&q=70",
                                                                                                                                                                        type: 'publicidade',
                                                                                                                                                                        effect: 'none',
                                                                                                                                                                        format: 'sd',
                                                                                                                                                                        },


                                                                                                                                                                        {
                                                                                                                                                                            id: 46,
                                                                                                                                                                            title: '0412_KSCOSM_07(A)',
                                                                                                                                                                            describe: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                                                                                                                                                                            url: '944543985',
                                                                                                                                                                            thumb: "https://i.vimeocdn.com/video/1849481357-e95e3cddc38fdb9b3735d5ea8148bfeac2c6e2d1f6341661d4f1639044259b6a-d?mw=500&mh=888&q=70",
                                                                                                                                                                            type: 'publicidade',
                                                                                                                                                                            effect: 'none',
                                                                                                                                                                            format: 'sd',
                                                                                                                                                                            },

                                                                                                                                                                            {
                                                                                                                                                                                id: 47,
                                                                                                                                                                                title: '0412_KSCOSM_04(P)',
                                                                                                                                                                                describe: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                                                                                                                                                                                url: '944543902',
                                                                                                                                                                                thumb: "https://i.vimeocdn.com/video/1849481288-bc467e6a6b47f8f9f682bec21c1e488d1e0a9e0874054e85aeebc3ebec348b95-d?mw=500&mh=888&q=70",
                                                                                                                                                                                type: 'publicidade',
                                                                                                                                                                                effect: 'none',
                                                                                                                                                                                format: 'sd',
                                                                                                                                                                                },

                                                                                                                                                                                {
                                                                                                                                                                                    id: 48,
                                                                                                                                                                                    title: '0315_JOAOALMEIDA_COCACOLA',
                                                                                                                                                                                    describe: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                                                                                                                                                                                    url: '944543720',
                                                                                                                                                                                    thumb: "https://i.vimeocdn.com/video/1849481126-97357c60fe9204676245be7ce9980c3039529ac6fd91222c8f4444bf31f172cc-d?mw=500&mh=888&q=70",
                                                                                                                                                                                    type: 'publicidade',
                                                                                                                                                                                    effect: 'none',
                                                                                                                                                                                    format: 'sd',
                                                                                                                                                                                    },

                                                                                                                                                                                    {
                                                                                                                                                                                        id: 49,
                                                                                                                                                                                        title: '0124_WAKERCHICKEN_PROCESS',
                                                                                                                                                                                        describe: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                                                                                                                                                                                        url: '944543306',
                                                                                                                                                                                        thumb: "https://i.vimeocdn.com/video/1849480385-ceb1251bd1db5e4979d3b93cf9d0afb7f7ff1ccf62214aaade1dd68253c000d3-d?mw=500&mh=888&q=70",
                                                                                                                                                                                        type: 'publicidade',
                                                                                                                                                                                        effect: 'none',
                                                                                                                                                                                        format: 'sd',
                                                                                                                                                                                        },


                                                                                                                                                                                        {
                                                                                                                                                                                            id: 50,
                                                                                                                                                                                            title: '0124_WAKER_CARDÁPIO',
                                                                                                                                                                                            describe: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                                                                                                                                                                                            url: '944543157',
                                                                                                                                                                                            thumb: "https://i.vimeocdn.com/video/1849480181-0743b3a8055aa496a2a03fed7c577e39990b1a1fc605d974b6085023cb0014c2-d?mw=500&mh=888&q=70",
                                                                                                                                                                                            type: 'publicidade',
                                                                                                                                                                                            effect: 'none',
                                                                                                                                                                                            format: 'sd',
                                                                                                                                                                                            },

                                                                                                                                                                                            {
                                                                                                                                                                                                id: 51,
                                                                                                                                                                                                title: 'UNACULTURE_REELS03',
                                                                                                                                                                                                describe: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                                                                                                                                                                                                url: '944220179',
                                                                                                                                                                                                thumb: "https://i.vimeocdn.com/video/1848983344-9fcdb85c087f639a77fa3981070856ee468f85576c040c5f427abc59e113f27f-d?mw=500&mh=888&q=70",
                                                                                                                                                                                                type: 'publicidade',
                                                                                                                                                                                                effect: 'none',
                                                                                                                                                                                                format: 'sd',
                                                                                                                                                                                                },


                                                                                                                                                                                                {
                                                                                                                                                                                                    id: 52,
                                                                                                                                                                                                    title: 'UNACULTURE_REELS02',
                                                                                                                                                                                                    describe: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                                                                                                                                                                                                    url: '944220146',
                                                                                                                                                                                                    thumb: "https://i.vimeocdn.com/video/1848983292-734714a76b9f67fd06fe758b5f4d34221d5e9c1f97eea91a70572088e725cfcb-d?mw=500&mh=888&q=70",
                                                                                                                                                                                                    type: 'publicidade',
                                                                                                                                                                                                    effect: 'none',
                                                                                                                                                                                                    format: 'sd',
                                                                                                                                                                                                    },


                                                                                                                                                                                                    {
                                                                                                                                                                                                        id: 53,
                                                                                                                                                                                                        title: 'UNACULTURE_REELS01_2',
                                                                                                                                                                                                        describe: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                                                                                                                                                                                                        url: '944220102',
                                                                                                                                                                                                        thumb: "https://i.vimeocdn.com/video/1848983194-a08f1ad8406dfe4e6ec2643bb5792221cdc6c2bea4a975061c21169e0071f265-d?mw=500&mh=888&q=70",
                                                                                                                                                                                                        type: 'publicidade',
                                                                                                                                                                                                        effect: 'none',
                                                                                                                                                                                                        format: 'sd',
                                                                                                                                                                                                        },

                                                                                                                                                                                                        {
                                                                                                                                                                                                            id: 54,
                                                                                                                                                                                                            title: 'UNACULTURE_IGTV',
                                                                                                                                                                                                            describe: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                                                                                                                                                                                                            url: '944220020',
                                                                                                                                                                                                            thumb: "https://i.vimeocdn.com/video/1848983108-aad43efc4037d408d1df74117ba991a5e6794846cfd0e29376711247007e61ac-d?mw=500&mh=888&q=70",
                                                                                                                                                                                                            type: 'publicidade',
                                                                                                                                                                                                            effect: 'none',
                                                                                                                                                                                                            format: 'sd',
                                                                                                                                                                                                            },


                                                                                                                                                                                                            {
                                                                                                                                                                                                                id: 55,
                                                                                                                                                                                                                title: 'SIMPLELIFE_3005_CAMP',
                                                                                                                                                                                                                describe: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                                                                                                                                                                                                                url: '944219988',
                                                                                                                                                                                                                thumb: "https://i.vimeocdn.com/video/1848982993-87f12bdf3d8f179eeb3e2b07f3188ad7cc0e6d8d38bb74d7c917549a575d906c-d?mw=500&mh=888&q=70",
                                                                                                                                                                                                                type: 'publicidade',
                                                                                                                                                                                                                effect: 'none',
                                                                                                                                                                                                                format: 'sd',
                                                                                                                                                                                                                },

                                                                                                                                                                                                                {
                                                                                                                                                                                                                    id: 56,
                                                                                                                                                                                                                    title: 'SIMPLELIFE_3005_CAMP',
                                                                                                                                                                                                                    describe: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                                                                                                                                                                                                                    url: '944219917',
                                                                                                                                                                                                                    thumb: "https://i.vimeocdn.com/video/1848982966-f16b5daa41af45f5752656f1aa531a3cf96e679781111823cf76b50bd5443785-d?mw=500&mh=888&q=70",
                                                                                                                                                                                                                    type: 'publicidade',
                                                                                                                                                                                                                    effect: 'none',
                                                                                                                                                                                                                    format: 'sd',
                                                                                                                                                                                                                    },


                                                                                                                                                                                                                    {
                                                                                                                                                                                                                        id: 57,
                                                                                                                                                                                                                        title: 'SIMPLELIFE_1503_FASHIONFILMDAY',
                                                                                                                                                                                                                        describe: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                                                                                                                                                                                                                        url: '944219877',
                                                                                                                                                                                                                        thumb: "https://i.vimeocdn.com/video/1848982862-d652c916bc4f07c9691078b613f0d2d027ab4071eb29a733a8fd0be3dc53686f-d?mw=500&mh=888&q=70",
                                                                                                                                                                                                                        type: 'publicidade',
                                                                                                                                                                                                                        effect: 'none',
                                                                                                                                                                                                                        format: 'sd',
                                                                                                                                                                                                                        },


                                                                                                                                                                                                                        {
                                                                                                                                                                                                                            id: 58,
                                                                                                                                                                                                                            title: 'PA_0706_REELS07',
                                                                                                                                                                                                                            describe: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                                                                                                                                                                                                                            url: '944219777',
                                                                                                                                                                                                                            thumb: "https://i.vimeocdn.com/video/1848982665-e7a44bfabc1b8ebffc13c524e70c483141a3555e1d1feda22e31659fdab6241e-d?mw=500&mh=888&q=70",
                                                                                                                                                                                                                            type: 'publicidade',
                                                                                                                                                                                                                            effect: 'none',
                                                                                                                                                                                                                            format: 'sd',
                                                                                                                                                                                                                            },

                                                                                                                                                                                                                            {
                                                                                                                                                                                                                                id: 59,
                                                                                                                                                                                                                                title: 'PA_0706_REELS05',
                                                                                                                                                                                                                                describe: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                                                                                                                                                                                                                                url: '944219745',
                                                                                                                                                                                                                                thumb: "https://i.vimeocdn.com/video/1848982626-91c341b27ee22e413bb205c466ae82aadad2a2e2d01900b29a1da0bd5702cfca-d?mw=500&mh=888&q=70",
                                                                                                                                                                                                                                type: 'publicidade',
                                                                                                                                                                                                                                effect: 'none',
                                                                                                                                                                                                                                format: 'sd',
                                                                                                                                                                                                                                },

                                                                                                                                                                                                                                {
                                                                                                                                                                                                                                    id: 60,
                                                                                                                                                                                                                                    title: 'NOURA_3103_FASHIONFILM',
                                                                                                                                                                                                                                    describe: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                                                                                                                                                                                                                                    url: '944219719',
                                                                                                                                                                                                                                    thumb: "https://i.vimeocdn.com/video/1848982588-74d6bacc1ddbd28c1386eb49bd422f81ac604c465954bb5407532bf11f021fd1-d?mw=500&mh=888&q=70",
                                                                                                                                                                                                                                    type: 'publicidade',
                                                                                                                                                                                                                                    effect: 'none',
                                                                                                                                                                                                                                    format: 'sd',
                                                                                                                                                                                                                                    },


                                                                                                                                                                                                                                    {
                                                                                                                                                                                                                                        id: 61,
                                                                                                                                                                                                                                        title: 'NOURA_3103_FASHIONFILM',
                                                                                                                                                                                                                                        describe: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                                                                                                                                                                                                                                        url: '944219677',
                                                                                                                                                                                                                                        thumb: "https://i.vimeocdn.com/video/1848982554-516ec92d92f07453b6a981d3619c72bb5e0fe42d4beddda794f3d773d79a54a3-d?mw=500&mh=888&q=70",
                                                                                                                                                                                                                                        type: 'publicidade',
                                                                                                                                                                                                                                        effect: 'none',
                                                                                                                                                                                                                                        format: 'sd',
                                                                                                                                                                                                                                        },


                                                                                                                                                                                                                                        
                                                                                                                                                                                                                                    {
                                                                                                                                                                                                                                        id: 62,
                                                                                                                                                                                                                                        title: 'NOURA_3103_FASHIONFILM (1)',
                                                                                                                                                                                                                                        describe: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                                                                                                                                                                                                                                        url: '944219639',
                                                                                                                                                                                                                                        thumb: "https://i.vimeocdn.com/video/1848982488-8efd9555c2372e84675939464f43447545a2e5d676bf40f5d5bdd3c51272ae4f-d?mw=500&mh=888&q=70",
                                                                                                                                                                                                                                        type: 'publicidade',
                                                                                                                                                                                                                                        effect: 'none',
                                                                                                                                                                                                                                        format: 'sd',
                                                                                                                                                                                                                                        },

                                                                                                                                                                                                                                        {
                                                                                                                                                                                                                                            id: 63,
                                                                                                                                                                                                                                            title: 'NOURA_3103_BTS',
                                                                                                                                                                                                                                            describe: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                                                                                                                                                                                                                                            url: '944219571',
                                                                                                                                                                                                                                            thumb: "https://i.vimeocdn.com/video/1848982460-14fcff7f695c6f3c6330212e9d4adf13e04527976fd73bd008354a7d3644586a-d?mw=500&mh=888&q=70",
                                                                                                                                                                                                                                            type: 'publicidade',
                                                                                                                                                                                                                                            effect: 'none',
                                                                                                                                                                                                                                            format: 'sd',
                                                                                                                                                                                                                                            },


                                                                                                                                                                                                                                            {
                                                                                                                                                                                                                                                id: 64,
                                                                                                                                                                                                                                                title: 'KARMANI_1207_ALP_REELS 02',
                                                                                                                                                                                                                                                describe: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                                                                                                                                                                                                                                                url: '944219523',
                                                                                                                                                                                                                                                thumb: "https://i.vimeocdn.com/video/1848982328-383ab02cfe1f67716067179951e7ed8155be144e691d9a5356ec7c78c82977b2-d?mw=500&mh=888&q=70",
                                                                                                                                                                                                                                                type: 'publicidade',
                                                                                                                                                                                                                                                effect: 'none',
                                                                                                                                                                                                                                                format: 'sd',
                                                                                                                                                                                                                                                },

                                                                                                                                                                                                                                                {
                                                                                                                                                                                                                                                    id: 65,
                                                                                                                                                                                                                                                    title: 'KARINHEITER_2106_VID05',
                                                                                                                                                                                                                                                    describe: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                                                                                                                                                                                                                                                    url: '944219487',
                                                                                                                                                                                                                                                    thumb: "https://i.vimeocdn.com/video/1848982249-bcfbff63129b591dde590f64a0a43d8d0f748feb49c3f48a5bc92b435064b061-d?mw=500&mh=888&q=70",
                                                                                                                                                                                                                                                    type: 'publicidade',
                                                                                                                                                                                                                                                    effect: 'none',
                                                                                                                                                                                                                                                    format: 'sd',
                                                                                                                                                                                                                                                    },

                                                                                                                                                                                                                                                    {
                                                                                                                                                                                                                                                        id: 66,
                                                                                                                                                                                                                                                        title: 'INNOCENCE_1907_BARBIECORE',
                                                                                                                                                                                                                                                        describe: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                                                                                                                                                                                                                                                        url: '944219432',
                                                                                                                                                                                                                                                        thumb: "https://i.vimeocdn.com/video/1848982154-b8c992287cac940918f6d5fd4731bb3d0e64446bcf14ebbaed9882583719aa39-d?mw=500&mh=888&q=70",
                                                                                                                                                                                                                                                        type: 'publicidade',
                                                                                                                                                                                                                                                        effect: 'none',
                                                                                                                                                                                                                                                        format: 'sd',
                                                                                                                                                                                                                                                        },


                                                                                                                                                                                                                                                        {
                                                                                                                                                                                                                                                            id: 67,
                                                                                                                                                                                                                                                            title: 'GULE_MAI22_04',
                                                                                                                                                                                                                                                            describe: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                                                                                                                                                                                                                                                            url: '944219396',
                                                                                                                                                                                                                                                            thumb: "https://i.vimeocdn.com/video/1848982125-af6fc82776ad85ea7cd1687b1578bca0b443021f872a79e26629d54c1544c178-d?mw=500&mh=888&q=70",
                                                                                                                                                                                                                                                            type: 'publicidade',
                                                                                                                                                                                                                                                            effect: 'none',
                                                                                                                                                                                                                                                            format: 'sd',
                                                                                                                                                                                                                                                            },

                                                                                                                                                                                                                                                            {
                                                                                                                                                                                                                                                                id: 68,
                                                                                                                                                                                                                                                                title: 'ESMERAL_JUN22_01',
                                                                                                                                                                                                                                                                describe: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                                                                                                                                                                                                                                                                url: '944219356',
                                                                                                                                                                                                                                                                thumb: "https://i.vimeocdn.com/video/1848982112-540f343733240f8277437969ed998e9c1ead531a758cbdefe88304c7e9a42c91-d?mw=500&mh=888&q=70",
                                                                                                                                                                                                                                                                type: 'publicidade',
                                                                                                                                                                                                                                                                effect: 'none',
                                                                                                                                                                                                                                                                format: 'sd',
                                                                                                                                                                                                                                                                },


                                                                                                                                                                                                                                                                {
                                                                                                                                                                                                                                                                    id: 69,
                                                                                                                                                                                                                                                                    title: 'AR_DAYDREAMING_FASHIONFILM',
                                                                                                                                                                                                                                                                    describe: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                                                                                                                                                                                                                                                                    url: '944219143',
                                                                                                                                                                                                                                                                    thumb: "https://i.vimeocdn.com/video/1848981815-8f404c87a1cda8200c9ca141ab0d097b5f957a451068ee147ae127c433ab73af-d?mw=500&mh=888&q=70",
                                                                                                                                                                                                                                                                    type: 'publicidade',
                                                                                                                                                                                                                                                                    effect: 'none',
                                                                                                                                                                                                                                                                    format: 'sd',
                                                                                                                                                                                                                                                                    },

                                                                                                                                                                                                                                                                    {
                                                                                                                                                                                                                                                                        id: 70,
                                                                                                                                                                                                                                                                        title: '1129_MAISUM_VD04',
                                                                                                                                                                                                                                                                        describe: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                                                                                                                                                                                                                                                                        url: '944219035',
                                                                                                                                                                                                                                                                        thumb: "https://i.vimeocdn.com/video/1848981572-e9b5bc59a7b410c3763f2189a99bf40cf6cc3a0ac54f632cd292a3c78ea8c3dc-d?mw=500&mh=888&q=70",
                                                                                                                                                                                                                                                                        type: 'publicidade',
                                                                                                                                                                                                                                                                        effect: 'none',
                                                                                                                                                                                                                                                                        format: 'sd',
                                                                                                                                                                                                                                                                        },


                                                                                                                                                                                                                                                                        {
                                                                                                                                                                                                                                                                            id: 71,
                                                                                                                                                                                                                                                                            title: '1129_MAISUM_VD01',
                                                                                                                                                                                                                                                                            describe: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                                                                                                                                                                                                                                                                            url: '944219007',
                                                                                                                                                                                                                                                                            thumb: "https://i.vimeocdn.com/video/1848981555-a7c433f9dc8278ef306ff3706003acc689b27f4a49323e8b24028016a0eda418-d?mw=500&mh=888&q=70",
                                                                                                                                                                                                                                                                            type: 'publicidade',
                                                                                                                                                                                                                                                                            effect: 'none',
                                                                                                                                                                                                                                                                            format: 'sd',
                                                                                                                                                                                                                                                                            },

                                                                                                                                                                                                                                                                            {
                                                                                                                                                                                                                                                                                id: 72,
                                                                                                                                                                                                                                                                                title: '1020_FERRI_VID01',
                                                                                                                                                                                                                                                                                describe: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                                                                                                                                                                                                                                                                                url: '944218478',
                                                                                                                                                                                                                                                                                thumb: "https://i.vimeocdn.com/video/1848980789-beed854f7d095e1e543965ad1ed2fae3cff878839f0b05fddfa36bc1901c8a34-d?mw=500&mh=888&q=70",
                                                                                                                                                                                                                                                                                type: 'publicidade',
                                                                                                                                                                                                                                                                                effect: 'none',
                                                                                                                                                                                                                                                                                format: 'sd',
                                                                                                                                                                                                                                                                                },


                                                                                                                                                                                                                                                                                {
                                                                                                                                                                                                                                                                                    id: 73,
                                                                                                                                                                                                                                                                                    title: '1005_SIMPLELIFE_11',
                                                                                                                                                                                                                                                                                    describe: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                                                                                                                                                                                                                                                                                    url: '944218425',
                                                                                                                                                                                                                                                                                    thumb: "https://i.vimeocdn.com/video/1848980736-bd07fb442605cc9feae581246184eb306b79e023fae756ed28f20580614d5f0d-d?mw=500&mh=888&q=70",
                                                                                                                                                                                                                                                                                    type: 'publicidade',
                                                                                                                                                                                                                                                                                    effect: 'none',
                                                                                                                                                                                                                                                                                    format: 'sd',
                                                                                                                                                                                                                                                                                    },

                                                                                                                                                                                                                                                                                    {
                                                                                                                                                                                                                                                                                        id: 74,
                                                                                                                                                                                                                                                                                        title: '0926_ABMQ_VID4.1',
                                                                                                                                                                                                                                                                                        describe: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                                                                                                                                                                                                                                                                                        url: '944218199',
                                                                                                                                                                                                                                                                                        thumb: "https://i.vimeocdn.com/video/1848980346-27327548c09cdebbe8071cd14ed346603698559007157e9c7372f44961851057-d?mw=500&mh=888&q=70",
                                                                                                                                                                                                                                                                                        type: 'publicidade',
                                                                                                                                                                                                                                                                                        effect: 'none',
                                                                                                                                                                                                                                                                                        format: 'sd',
                                                                                                                                                                                                                                                                                        },
                                                            
                                                        
                                                    
                                                
                                                
                                    
                                
                    
                
            
          
        

  ];
export default videos;
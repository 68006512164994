import React, {  useEffect, useState } from 'react';
import Header from '../components/Header/Header';
import { Button, Col, Container, Row } from 'react-bootstrap';
import FooterDark from '../components/Footer/FooterDark';
import FontFilmaker  from '../assets/font-filmaker.png';
import ReactPlayer from 'react-player';
import Vmeo from '../components/Vimeohook/Vmeo';
import Filmake from '../assets/PHOTOGRAPHY.png'
import Slider from '../components/Slider/Slider';
import LogoProduction from '../assets/production-img.png';
import EtapasDeProdução from '../assets/etapasdeprodução.png';
import Fotografia from '../assets/PHOTOGRAPHY.png'
import foto from '../assets/foto.png'
import { Link } from 'react-router-dom';
import LookBook from  '../assets/LookBook.jpg';
import Campanha from  '../assets/Campanha.jpg';
import Still from  '../assets/Still.jpg';


const Serviços = () => {

const[playVideo,setPlayVideo] = useState(true);
const[selecUrl, setSelectUrl] = useState('');
const[son, setSon] = useState(true);
const[son2, setSon2] = useState(true);


  const VideosPromo = [
    {
      id: 1,
      title: 'AMONAFANY FASHION FILM',
      describe: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna",
      url: '903795868',
      thumb: "https://i.vimeocdn.com/video/1784150714-d4d5149d741b0c58cd22f3dd5089dad6906128deb15fb7d64f285b2d62549dfb-d?mw=1500&mh=844&q=70",
      type: 'fashion',
      effect: 'none',
      format: 'hd',
    },
    {
      id: 2,
      title: 'PA CONCEPT FASHION FILM - JULHO',
      describe: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna",
      url: '904102381',
      thumb: "https://i.vimeocdn.com/video/1783498408-5685c31c20a3cf50c9d921a867ff204da7f1f2097457adf082a516213e8868b3-d?mw=5000&mh=2813&q=70",
      type: 'fashion',
      effect: 'none',
      format: 'sd',
    },
    {
    id: 3,
    title: 'Edge Production Talgui - Cosmopolitan',
    describe: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna",
    url: '904096775',
    thumb: "https://i.vimeocdn.com/video/1626696791-8c39eaa131b6fd698f9d32abd5ef5bbdd3b9ea3b36f9a455daa11e01cace1278-d?mw=1500&mh=844&q=70",
    type: 'fashion',
    effect: 'none',
    format: 'sd',
    },
{
    id: 4,
    title: 'Titulo 4',
    describe: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna",
    url: '903853638',
    thumb: "https://i.vimeocdn.com/video/1784138836-98e80246e547c5f78911f38ad6c610bbfff2993d13e08360808c38f67698c89f-d?mw=1500&mh=844&q=70",
    type: 'fashion',
    effect: 'none',
    format: 'sd',
    }
  ];



  return (
    <div className="fluid">
      <div style={{ marginBottom: '150px'}}>
         <Header />
      </div>
      {/* https://vimeo.com/805624395 */}
        <Row>
             <Col  className='text-center '>
             
              <div className='video-scholl'>
              <ReactPlayer 
               className='video-scholl-r'
              url={`https://vimeo.com/805624395`}
              width={'840px'}          
              height={'475px'}    
              playing={playVideo}
                muted={son}
                        loop
                        />
              </div>
       
             </Col>
        </Row>
        <Row>
            <Col  className='text-center'>
            <div className='title-school2'>
                <h1 className='title-school-h42'>EDGE, a produtora audiovisual:</h1>
                        <p>Na Edge Production, especializamo-nos em criar conteúdos visualmente 
                          impactantes e envolventes. Transformamos a sua história em um produto 
                          de audiovisual de forma criativa e com qualidade, tornando cada campanha 
                          algo único e especial. Nós trazemos uma visão 
                          jovem com uma linguagem moderna e inovadora para nossos clientes.
                       </p>
             </div>
            </Col>
        </Row>
        <Row>
            <Col className='text-center'>
               <div  className='LogoProduction'/>
                <div >
                   <img src={LogoProduction} alt='img' width={'300px'}/>
                </div>
            </Col>
        </Row>
        <Row className=' text-center'>
           <Col md={5} className=' text-center'>
           <ReactPlayer 
              clasaName ='videosPromoServicos'
              url={`https://vimeo.com/904101575`}
              width={'400px'}          
              height={'700px'}  
              playing={playVideo}
                muted={son}
                        loop
                        />
                        </Col>
           <Col md={5} className=' text-center'>
           <ReactPlayer 
              clasaName ='videosPromoServicos'
              url={`https://vimeo.com/811050652`}
              width={'400px'}          
              height={'700px'}    
              playing={playVideo}
                muted={son}
                        loop
                        />
           </Col>
        </Row>
        

        <Row>
        <Col className='text-center'>
               <div  className='LogoProduction'/>
                <div >
                   <img src={EtapasDeProdução} alt='img' width={'520px'}/>
                </div>
            </Col>

        </Row>
        <Row >
           <Col className='text-center'>
              <h1 className='FontEtapas'>DESENVOLVIMENTO</h1>
              
           </Col>
           <Col>
           <p className='ParaGrafoEtapas'>
           Nessa etapa é preparado os roteiros, cronogramas, pesquisas de locações, logística entre outras atividades para que no dia do shooting não falte nada.   
            </p></Col>
        </Row>
        <Row>
          <Col className='text-center'>
             <div className='linha'/>
          </Col>
        </Row>
        <Row >
           <Col className='text-center'>
              <h1 className='FontEtapas'>PRÉ PRODUÇÃO:</h1>
              
           </Col>
           <Col>
           <p className='ParaGrafoEtapas'>
           Nessa etapa é preparado os roteiros, cronogramas, pesquisas 
           de locações, logística entre outras atividades para que no dia do shooting não falte nada. 
            </p></Col>
        </Row>
        <Row>
          <Col className='text-center'>
             <div className='linha'/>
          </Col>
        </Row>
        <Row >
           <Col className='text-center'>
              <h1 className='FontEtapas'>CAPTAÇÃO:</h1>
              
           </Col>
           <Col>
           <p className='ParaGrafoEtapas'>
           É o estágio onde a filmagem real ocorre. A equipe trabalha no set 
           de filmagem de acordo com o roteiro e as diretrizes estabelecidas 
           durante a pré-produção. Este é o momento em que são capturadas as 
           imagens e o áudio que serão usados na versão final do projeto.
            </p></Col>
        </Row>
        <Row>
          <Col className='text-center'>
             <div className='linha'/>
          </Col>
        </Row>
        <Row >
           <Col className='text-center'>
              <h1 className='FontEtapas'>PÓS PRODUÇÃO:</h1>
              
           </Col>
           <Col>
           <p className='ParaGrafoEtapas'>
           Todo o material é revisado e editado. Isso inclui edição de vídeo,
            mixagem de áudio, criação de efeitos visuais e tudo o mais 
           necessário para finalizar o projeto de acordo com a visão criativa inicial.
            </p></Col>
        </Row>
        <Row>
          <Col className='text-center'>
             <div className='linha'/>
          </Col>
        </Row>

        <Row className='section-color'>
            <Col>
          <div className='space-div'>
             <h1 className='title-colorProdu'>FASHION</h1>
             <p>VÍDEO LOOKBOOK / FASHION REELS / PROVADOR TÉCNICO </p>
             <p>CONTEÚDO PARA AS REDES SOCIAIS / INSTITUCIONAL DE MARCA
             </p>
          </div>
          </Col>

          <Col>
          <div className='space-div'>
             <h1 className='title-colorProdu'>PRODUTO</h1>
             <p>COLEÇÃO / CAMPANHA / MOSTRUÁRIO / GIF</p>
            
          </div>
          </Col>
          
        </Row>
        <Row className='section-color'>
            <Col>
          <div className='space-div'>
             <h1 className='title-colorProdu'>ENTRETENIMENTO</h1>
             <p>PUBLICIDADE / CAMPANHA / REELS</p>
             <p>TIKTOK / YOUTUBE
             </p>
          </div>
          </Col>

          <Col>
          <div className='space-div'>
             <h1 className='title-colorProdu'>INSTITUCIONAL</h1>
             <p>DOCUMENTÁRIO / HISTÓRIA DA EMPRESA / EVENTO</p>
            
          </div>
          </Col>
          
        </Row>
        <Row>
        <Col className='text-center'>
             
                <div className='LogoPotografia'>
                   <img src={Fotografia} alt='img' width={'380px'}/>
                </div>
            </Col>

        </Row>

        <Row>
             <Col  className='text-center '>
             
              <div className='video-scholl'>
              <ReactPlayer 
               className='video-scholl-r'
              url={`https://vimeo.com/903412590`}
              width={'840px'}          
              height={'475px'}    
              playing={playVideo}
                muted={son}
                        loop
                        />
              </div>
       
             </Col>
        </Row>

        <Row className=' text-center'>
           <Col md={3} className=' text-center'>
              <div>

                 <img  src={LookBook} alt='img' width={'200px'} style={{ borderRadius: '10%'}}/>
                  <h1 id='h1-1'>Fashion Lookbook</h1>
                  <p style={{color: '#fff'}}>Criação de conteúdo audiovisual para ser exibido 
                     em pontos de venda, como telas de TV, para atrair a atenção dos clientes, 
                     informar sobre promoções e incentivar a compra.</p>
              </div>
                        </Col>
           <Col md={3} className=' text-center'>
           <div>

                 <img  src={Campanha} alt='img' width={'200px'} style={{ borderRadius: '10%'}}/>
                  <h1 id='h1-1'>Campanha</h1>
                  <p style={{color: '#fff'}}>Criação de conteúdo audiovisual 
                     para ser exibido em pontos de venda, como telas de TV, 
                     para atrair a atenção dos clientes, 
                     informar sobre promoções e incentivar a compra.</p>
              </div>
           </Col>
           <Col md={3} className=' text-center'>
           <div>

            <img  src={Still} alt='img' width={'200px'} style={{ borderRadius: '10%'}}/>
            <h1 id='h1-1'>Still</h1>
            <p style={{color: '#fff'}}>Criação de conteúdo audiovisual para ser exibido 
               em pontos de venda, como telas de TV, para atrair a atenção dos clientes, 
               informar sobre promoções e incentivar a compra.</p>
            </div>
           </Col>
        </Row>
        <Row>
           <Col className='text-center'>
                 <div>
                    <h1 id='h1-2'>Se interessou? </h1>
                    <Link to={'/contato'}>
                     <button className='btn-f' >
                           Faça um orçamento
                     </button>
                    </Link>
                    <p style={{ color: "#fff", padding: "20px  "}}>PROJETOS A PARTIR DE R$5.000</p>
                 </div>
           </Col>
        </Row>
        

     <FooterDark />
    </div>
    
  )

}
export default Serviços;

import React from 'react';
import {Row, Col} from 'react-bootstrap';
import Vimeohook from './Vimeohook';
import { motion } from 'framer-motion';

const HookCenter = ({url , TitleVideo, description})=>{
    return(
        <Row className='text-center'>
           <Col md={8} >
              <motion.div className={"div-absolute"}>
                  <Vimeohook  autoplay={true} TitleVideo={TitleVideo} muted={false} url={url} width={"600px"} controls={true}/>
              </motion.div>
              
           </Col>
        </Row> 
    )
}

export default HookCenter;
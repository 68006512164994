import React, { useEffect, useState } from 'react';
import Header from '../components/Header/Header';
// import Vimeohook from '../components/Vimeohook/Vimeohook';
import HookCenter from '../components/Vimeohook/HookCenter';
import { Col, Row } from 'react-bootstrap';
import FooterDark from '../components/Footer/FooterDark';
// import { Col, Row } from 'react-bootstrap';
import videos from '../components/Videos';
import { motion } from 'framer-motion';



const Portfolio = () => {
 

  const [filterType, setFilterType] = useState('');
  const [filterEffect, setFilterEffect] = useState('');
  const [filterFormat, setFilterFormat] = useState('');
  const [show, setShow] = useState(false);
  const [urlItem, setUrlItem] = useState('');

  const filteredVideos = videos.filter(video => {
    return (
      (filterType === '' || video.type === filterType) &&
      (filterEffect === '' || video.effect === filterEffect) &&
      (filterFormat === '' || video.format === filterFormat)
    );
  });

  const handleShowVideo = (url) => {
    setShow(true);
    setUrlItem(url);
  };
useEffect(()=>{
  
},[show]);



  return (
    <div className="container">
      <div style={{ marginBottom: '150px'}}>
         <Header />
      </div>
      <Row className='text-center'>
        <Col>
           <div className='text-center col-title-portfolio'>
               <h1 className='title-portifolio'>Portifolio</h1>
               <p>Nossa missão é criar conteúdos audiovisuais que emocionem, informem e inspirem.
               Bem-vindo ao nosso portfólio. Aqui, você encontrará uma seleção de nossos trabalhos mais 
               recentes, destacando nossa versatilidade e compromisso com a qualidade.</p>
           </div>
        </Col>
      </Row>
        
      <div className="video-list">
      

        <div className="filters">
          {/* <label>Tipo:</label> */}
          {/* <select value={filterType} onChange={(e) => setFilterType(e.target.value)}>
            <option value="">Todos</option>
            <option value="fashion">Fashion Film</option> 
            <option value="fashion reals">Fashion Reals</option>
            <option value="publicidade">Publicidade</option>
          </select> */}

          {/* <label>Efeito:</label> */}
          {/* <select value={filterEffect} onChange={(e) => setFilterEffect(e.target.value)}>
            <option value="">Todos</option>
            <option value="none">Nenhum</option>
            <option value="sepia">Sépia</option>
            <option value="blur">Desfoque</option>
          </select> */}

          {/* <label>Formato:</label> */}
          {/* <select value={filterFormat} onChange={(e) => setFilterFormat(e.target.value)}>
            <option value="">Todos</option>
            <option value="hd">HD</option>
            <option value="sd">SD</option>
          </select> */}
        </div>

        <div className="video-grid">
          {filteredVideos.map((video) => (
            <motion.div 
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1  }}
            transition={{
              duration: 3.8,
              delay: 0.5,
              ease: [0, 0.71, 0.2, 1.01]
            }}

            className="div-card-flex" id={video.id} onClick={() => {
              if (show === false) {
                setShow(true);
                handleShowVideo(video.url);
              } else {
                setShow(false);
              }
          }}>
              <img src={video.thumb} alt="Capa" width="425px" height="auto" className='img-thumb'/>
              {show && urlItem === video.url ? <HookCenter TitleVideo={video.title} description={video.describe} url={video.url} onClick={() => {
               if (show === false) {
                setShow(true); 
                handleShowVideo(video.url);
              } else {
                setShow(false);
              }
              }} /> : null}
            </motion.div>
          ))}
        </div>
      </div>
     <FooterDark />
    </div>
    
  )

}
export default Portfolio;

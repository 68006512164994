import React from "react";
import {Row, Col} from 'react-bootstrap';
import Logo from '../../assets/logo-edge.png';
import { Link } from "react-router-dom";
import {motion} from 'framer-motion';



const Header = () =>{
    return(
     <div>
        <Row className="col1">
            <Col>
              <motion.div   
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{
                duration: 0.8,
                delay: 0.5,
                ease: [0, 0.71, 0.2, 1.01]
              }}
              className="itens-menu">
              {/* <Link to={"#section2"}>SERVIÇOS</Link>  */}
              {/* <Link to="/school">SCHOOL</Link>  */}
              <Link to="/servicos">SERVIÇOS </Link> 
              <Link to="/portifolio">PORTIFOLIO </Link> 
               
              </motion.div>
            </Col>
            <Col md={2}>
                <motion.div  
              initial={{ translateY: 130, opacity: 0 }}
              animate={{ translateY: 0, opacity: 1 }}
              transition={{
                duration: 2,
                delay: 0.9,
                ease: [0, 0.71, 0.2, 1.01]
              }}
              className="logo-edge" >
                <Link to="/">
                    <img src={Logo} alt="logo Edge " width={'150px'} />
                </Link>
               </motion.div>
            
            </Col>

            <Col>
              <motion.div  
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{
                duration: 0.8,
                delay: 0.5,
                ease: [0, 0.71, 0.2, 1.01]
              }}
              className="itens-menu2">
              <Link to="/sobre">SOBRE NÓS</Link> 
              <Link to="/contato">CONTATO</Link> 
            
              </motion.div>
            </Col>
           

        </Row>
        {/* <Row className="">
          

           
           
         
        </Row> */}
     </div>
       


    )
}

export default Header;
import React, { useEffect, useState } from 'react';
import Header from '../components/Header/Header';
// import Vimeohook from '../components/Vimeohook/Vimeohook';
import HookCenter from '../components/Vimeohook/HookCenter';
import { Col, Row } from 'react-bootstrap';
import FooterDark from '../components/Footer/FooterDark';
// import { Col, Row } from 'react-bootstrap';
// import Footer from '../components/Footer/Footer';

const Publicidade = () => {
  const videos = [
    {
      id: 1,
      title: 'AMONAFANY FASHION FILM',
      describe: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna",
      url: '903851449',
      thumb: "https://i.vimeocdn.com/video/1784150714-d4d5149d741b0c58cd22f3dd5089dad6906128deb15fb7d64f285b2d62549dfb-d?mw=1500&mh=844&q=70",
      type: 'fashion',
      effect: 'none',
      format: 'hd',
    },
    {
      id: 2,
      title: 'PA CONCEPT FASHION FILM - JULHO',
      describe: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna",
      url: '903412590',
      thumb: "https://i.vimeocdn.com/video/1783498408-5685c31c20a3cf50c9d921a867ff204da7f1f2097457adf082a516213e8868b3-d?mw=5000&mh=2813&q=70",
      type: 'fashion',
      effect: 'none',
      format: 'sd',
    },
    {
    id: 3,
    title: 'Edge Production Talgui - Cosmopolitan',
    describe: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna",
    url: '805624395',
    thumb: "https://i.vimeocdn.com/video/1626696791-8c39eaa131b6fd698f9d32abd5ef5bbdd3b9ea3b36f9a455daa11e01cace1278-d?mw=1500&mh=844&q=70",
    type: 'fashion',
    effect: 'none',
    format: 'sd',
    },
{
    id: 4,
    title: 'Titulo 4',
    describe: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna",
    url: '903844118',
    thumb: "https://i.vimeocdn.com/video/1784138836-98e80246e547c5f78911f38ad6c610bbfff2993d13e08360808c38f67698c89f-d?mw=1500&mh=844&q=70",
    type: 'fashion',
    effect: 'none',
    format: 'sd',
    }
    ,
    {
      id: 5,
      title: 'AVERARA FASHION FILM - JUNHO',
      describe: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna",
      url: '903849448',
      thumb: "https://i.vimeocdn.com/video/1784146525-c8576f658711d9b0a6132e8373cab7ed2fafa4450726a0da16049e4c86836b74-d?mw=1500&mh=844&q=70",
      type: 'fashion',
      effect: 'none',
      format: 'sd',
      }
      ,
      {
        id: 6,
        title: 'AVERARA FASHION FILM - SUMMER',
        describe: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna",
        url: '903846819',
        thumb: "https://i.vimeocdn.com/video/1784142231-53adb594996eb4ee49012960af031752d9e5c09835fb862ea1a813ebfd7354fe-d?mw=1500&mh=844&q=70",
        type: 'fashion',
        effect: 'none',
        format: 'sd',
        },
     
          {
            id: 7,
            title: 'SIMPLE LIFE FASHION FILM - NIGHT',
            describe: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
            url: '903795868',
            thumb: "https://i.vimeocdn.com/video/1784069828-ae69458926e73b8a3e8d8b1b03550b8f5e5819edb01d68670e622ec77f56fbd4-d?mw=500&mh=888&q=70",
            type: 'fashion',
            effect: 'none',
            format: 'sd',
            },
            {
              id: 8,
              title: 'SIMPLE LIFE FASHION FILM - MAIO',
              describe: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
              url: '903794837',
              thumb: "https://i.vimeocdn.com/video/1784068415-1168b1d3b67dc5c539adac41767d115f0344978009d95573ad87b7bca61220fb-d?mw=500&mh=888&q=70",
              type: 'fashion',
              effect: 'none',
              format: 'sd',
              },
              {
                id: 9,
                title: 'SIMPLE LIFE FASHION FILM - NIGHT',
                describe: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                url: '903795868',
                thumb: "https://i.vimeocdn.com/video/1784069828-ae69458926e73b8a3e8d8b1b03550b8f5e5819edb01d68670e622ec77f56fbd4-d?mw=500&mh=888&q=70",
                type: 'fashion',
                effect: 'none',
                format: 'sd',
                },
                {
                  id: 10,
                  title: 'SIMPLE LIFE FASHION FILM - NIGHT',
                  describe: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                  url: '903795868',
                  thumb: "https://i.vimeocdn.com/video/1784069828-ae69458926e73b8a3e8d8b1b03550b8f5e5819edb01d68670e622ec77f56fbd4-d?mw=500&mh=888&q=70",
                  type: 'fashion',
                  effect: 'none',
                  format: 'sd',
                  }
        

  ];

  const [filterType, setFilterType] = useState('');
  const [filterEffect, setFilterEffect] = useState('');
  const [filterFormat, setFilterFormat] = useState('');
  const [show, setShow] = useState(false);
  const [urlItem, setUrlItem] = useState('');

  const filteredVideos = videos.filter(video => {
    return (
      (filterType === '' || video.type === filterType) &&
      (filterEffect === '' || video.effect === filterEffect) &&
      (filterFormat === '' || video.format === filterFormat)
    );
  });

  const handleShowVideo = (url) => {
    setShow(true);
    setUrlItem(url);
  };
useEffect(()=>{
  
},[show]);



  return (
    <div className="container">
      <div style={{ marginBottom: '150px'}}>
         <Header />
      </div>
      <Row className='text-center'>
        <Col>
           <div className='text-center col-title-portfolio'>
               <h1 className='title-portifolio'>Publicidade</h1>
               <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
                sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                Quis ipsum suspendisse ultrices gravida. Risus commodo viverra 
                maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, 
                consectetur adipiscing elit, sed do eiusmod tempor incididunt ut 
                labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. 
                Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
           </div>
        </Col>
      </Row>
        
      <div className="video-list">
        <h2>Filtro de Vídeos</h2>

      

        <div className="video-grid">
          {filteredVideos.map((video) => (
            <div className="div-card-flex" id={video.id} onClick={() => {
              if (show === false) {
                setShow(true);
                handleShowVideo(video.url);
              } else {
                setShow(false);
              }
          }}>
              <img src={video.thumb} alt="Capa" width="360px" height="auto" className='img-thumb'/>
              {show && urlItem === video.url ? <HookCenter TitleVideo={video.title} description={video.describe} url={video.url} onClick={() => {
               if (show === false) {
                setShow(true); 
                handleShowVideo(video.url);
              } else {
                setShow(false);
              }
              }} /> : null}
            </div>
          ))}
        </div>
      </div>
     <FooterDark />
    </div>
    
  )

}
export default Publicidade;

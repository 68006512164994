import React, { useState } from "react";
import logo from '../../assets/logo-edge.png';
import { Row, Col, Container} from 'react-bootstrap';



const HeaderMobile = () =>{

  const [isOpen, setIsOpen] = useState(false)

  const toggleMenu = () => setIsOpen(!isOpen);
    return(
        <Container fluid className="bg-menu-header">
          <Row>
              <Col >
                  <div className="img-mobile-logo">
                     <img src={logo} width={'90px'} alt="logo Edge"/>
                  </div>
              </Col>

              <Col className="text-">
                    
              </Col>
          </Row>
         
        </Container>
    )
}


export default HeaderMobile;
import React from 'react';
import ReactDOM from 'react-dom/client';
import './css/global.css';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import PortFolio from './pages/Portfolio'; // Corrected spelling
import Fashion from './pages/Fashion';
import Contato from './pages/Contato';
import Publicidade from './pages/Publicidade';
import Produção from './pages/Produção';
import Institucional from './pages/Institucional';
import School from './pages/School';
import Home from './pages/Home';
import Sobre from './pages/Sobre';
import { isMobile } from 'react-device-detect'; // Removed unnecessary import
import HomeMobile from './pages/HomeMobile';
import Serviços from './pages/Serviços';

const router = createBrowserRouter([
  {
    path: '/',
    element: isMobile ? <HomeMobile/> : <Home/> // Only render Home for mobile
  },
  {
    path: '/portifolio',
    element: <PortFolio />,
  },
  {
    path: '/fashion',
    element: <Fashion />,
  },
  {
    path: '/contato',
    element: <Contato />,
  },
  {
    path: '/publicidade',
    element: <Publicidade />,
  },
  {
    path: '/producao',
    element: <Produção />,
  },
  {
    path: '/institucional',
    element: <Institucional />,
  },
  {
    path: '/school',
    element: <School />,
  },
  {
    path: '/sobre',
    element: <Sobre />,
  },

  {
    path: '/servicos',
    element: <Serviços/>,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

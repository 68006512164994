import React from "react";
import { Container , Row, Col} from "react-bootstrap";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Instagram from '@mui/icons-material/Instagram';
import Twitter from '@mui/icons-material/X';
import Vimeo from '../../assets/Ativo 1vimeo.png';
import { Link } from "react-router-dom";

const FooterDark = () =>{
    return(
        <Container className="footer-area">
            <Row>
                <Col>
                </Col>
              <Col md={5}>
               <div className="section-social-itens2">

                    <h2 className="social-title">SIGA A GENTE</h2>
                  <div className="social-icons">
                  <Link to={"https://www.instagram.com/edge.production_/"} className="links-1">
                        <Instagram className="icon" fontSize="134px" />
                    </Link>
                    <Link to={"#"} className="links-1">
                        <Twitter className="icon" fontSize="130px"/>
                    </Link >

                    <Link to={"https://vimeo.com/edgeproductionbr"} className="links-1">
                        <img src={Vimeo}  alt="Vimeo" width={'30px'}/>
                    </Link>

                    <Link to={"https://www.linkedin.com/company/edgeproduction/posts/?feedView=all"} className="links-1">
                        <LinkedInIcon className="icon" fontSize="134px"/>
                    </Link>
                  </div>
                 </div>
              </Col>
              <Col md={5}>
                 <div className="subtitle-end">
                        <p className="p-footer">
                            Rua José Paulino, 795, 2nd and. Bom Retiro - São Paulo
                        </p>

                        <p className="p-footer"> CEP. 01125-001</p>
                        <p className="p-footer"> +55 (11) 95769-5733</p>
                        <p className="p-footer"> www.edgeproduction.com.br</p>
                       
                     </div>

              </Col>
              <Col>
                </Col>
            </Row>
        </Container>
    )
}

export default FooterDark;
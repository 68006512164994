import React, { useEffect, useRef, useState } from "react";
import Header from "../components/Header/Header";
import Footer from '../components/Footer/Footer';
import Igm1 from '../assets/img-01.png';
import Igm2 from '../assets/img-02.png';
import Igm3 from '../assets/img-03.png';
import Igm4 from '../assets/img-04.png';
import { Container, Row , Col} from "react-bootstrap";
import useResizeHeaderHook from "../components/hooks/useResizeHeaderHook";
import HeaderMobile from '../components/HeaderMobile/HeaderMobile';
import Cards from '../components/Cards/Cards';
import { motion } from "framer-motion";
import VimeoPlayer from "react-player/vimeo";
import GraphicEqIcon from '@mui/icons-material/GraphicEq';
import Edge from '../assets/edge-aprodutora.png';
import Apple from  '../assets/logos/APPLE.png';
import CHCH from  '../assets/logos/CHCH.png';
import Cacaushow from  '../assets/logos/Cacaushow.png';
import kiss from  '../assets/logos/kiss.png';
import prime from  '../assets/logos/prime.png';
import nissan from  '../assets/logos/nissan.png';
import seara from  '../assets/logos/seara.png';
import benneton from  '../assets/logos/benneton.png';
import stanley from  '../assets/logos/stanley.png';
import disnep from  '../assets/logos/disnep.png';
import fast from  '../assets/logos/FAST.png';
import CocaCola from  '../assets/logos/cocacola.png';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import {Link, Element} from 'react-scroll';
import AwesomeSlider from 'react-awesome-slider';
import CoreStyles from 'react-awesome-slider/src/core/styles.scss';
import AnimationStyles from 'react-awesome-slider/src/styled/fold-out-animation/fold-out-animation.scss';
import Slider2 from "../components/Slider2/Slider2";
import Slider3 from "../components/Slider4/Slider4";
import Slider4 from "../components/Slider4/Slider4";



const Home = ()=>{
const {showHeader} = useResizeHeaderHook();
const[showVideo, setShowVideo] = useState(false);

const[mudo, setMudo]=useState(true);
const[mudo2, setMudo2]=useState(true);

const iframeRef = useRef(null);



const handleClick = () => {
  setMudo((pevMudo)=> !mudo); // Call the PlaySound function directly
  
};


useEffect(()=>{
    setTimeout(()=>{
         setShowVideo(true);
    },1000);
},[showHeader])

    return(
        <>
       {showHeader? <Header/>  : <HeaderMobile/>}
       
        <div  className="bg-banner">            
            <div className="video-banner" >
                {showVideo? 
                
                <VimeoPlayer         
              
                url={"https://vimeo.com/934997073"}          
                playing={showVideo}          
                loop  
                // onStart={(start)=>alert('video iniciado')}
                onError={(err)=>console.log(err)}       
                muted={mudo}       
                width={'100%'}          
                height={'100%'}         
            />   : null
            
            }
           
            </div>
            
        </div>
            <Container className="section-2" fluid>
            <motion.div  initial={{ opacity: 0, translateY: 100 }}  
                 animate={{opacity: 1, translateY: 0}} 
                 transition={{  duration: 2, delay: 2.2,  ease:[0, 0.71, 0.2, 1.01] }}
                  className="playable-iframe-container" onClick={handleClick}>
            <GraphicEqIcon fontSize="large" className="icon-style"/>
            <p className="P-BTN">PRESS BUTTON TO PLAY SOUND</p>
                {/* <iframe title="sounde"
                src="https://lottie.host/embed/3c3b4a08-ff72-4b10-924b-fad348d4d20b/sG4Y3M2Vd9.json" 
                width="600" height="400"></iframe> */}
             </motion.div>

           
            </Container>
            
            <Container className="bloco-logo">
                <Row>
                    <Col className="text-center">
                        <div className="logo-edgeprodutora">
                         <img src={Edge} alt="edge produtora" />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col className="text-center">
                    
                    <div className="video-2-home">
                    
                    <VimeoPlayer  
                        className="video-2-iframe"       
                        url={"https://vimeo.com/903851449"}          
                        playing={showVideo}          
                        loop  
                        // onStart={(start)=>alert('video iniciado')}
                        onError={(err)=>console.log(err)}       
                        muted={mudo2}       
                        width={'840px'}          
                        height={'475px'}         
                    /> 

                    </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="text-center text-video1">
                          <h1 id="textt-video2">Se da para imaginar. da pra criar.</h1>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col></Col>
                    <Col md={7}>
                      <h4 style={{ color: "#fff", textAlign: 'center'}}>Bem-vindo à EDGE Production:</h4>
                      <br></br>

                      <h4 style={{ color: "#fff", textAlign: 'center'}}>Onde Criatividade e Profissionalismo Se Encontram!</h4>
                      <p className="p-home">
                      Descubra o mundo vibrante da produção audiovisual com a EDGE Production. Somos uma equipe jovem 
                      e dinâmica, trazendo uma abordagem fresca e criativa para cada projeto. Seja parte da nossa 
                      jornada enquanto exploramos as últimas tendências e entregamos conteúdo de alta qualidade 
                      para marcas e públicos jovens.
                      </p>
                    </Col>
                    <Col></Col>
                </Row>

                <Row>
                      <Col>
                        <div className="text-center text-video2">
                          <h1 id="textt-video2">Marcas que já trabalhamos</h1>
                          </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={1}></Col>
                    <Col>
                        <div className="logos text-center">
                            <img src={kiss} alt="kiss" width="236px" height="91px"/>
                        </div>
                    </Col>
                    <Col>
                         <div className="logos text-center">
                             <img src={CocaCola} alt="CocaCola" width="236px" height="91px"/>
                        </div>
                    </Col>
                    <Col>
                        <div className="logos text-center">
                             <img src={prime} alt="kiss" width="236px" height="91px"/>
                        </div>
                    </Col>
                    <Col md={1}></Col>
                </Row>

                <Row>
                    <Col md={1}></Col>
                    <Col>
                        <div className="logos text-center">
                            <img src={nissan} alt="nissan" width="236px" height="91px"/>
                        </div>
                    </Col>
                    <Col>
                         <div className="logos text-center">
                             <img src={seara} alt="seara" width="236px" height="91px"/>
                        </div>
                    </Col>
                    <Col>
                        <div className="logos text-center">
                             <img src={benneton} alt="kiss" width="236px" height="91px"/>
                        </div>
                    </Col>
                    <Col md={1}></Col>
                </Row>

                <Row>
                    <Col md={1}></Col>
                    <Col>
                        <div className="logos text-center">
                            <img src={stanley} alt="stanley" width="236px" height="91px"/>
                        </div>
                    </Col>
                    <Col>
                         <div className="logos text-center">
                             <img src={disnep} alt="CocaCola" width="236px" height="91px"/>
                        </div>
                    </Col>
                    <Col>
                        <div className="logos text-center">
                             <img src={Cacaushow} alt="kiss" width="236px" height="91px"/>
                        </div>
                    </Col>
                    <Col md={1}></Col>
                </Row>

                <Row>
                    <Col md={1}></Col>
                    <Col>
                        <div className="logos text-center">
                            <img src={CHCH} alt="CHCH" width="200px" height="auto"/>
                        </div>
                    </Col>
                    <Col>
                         <div className="logos text-center">
                             <img src={fast} alt="CocaCola" width="236px" height="91px"/>
                        </div>
                    </Col>
                    <Col>
                        <div className="logos text-center">
                             <img src={Apple} alt="kiss" width="200px" height="auto"/>
                        </div>
                    </Col>
                    <Col md={1}></Col>
                </Row>
            </Container>


         
            <div className="div-slider">              
                <Slider4/>
                                 
              </div> 

              {/* <div whileHover={{translateY: -40 , transition: { duration: 0.3, ease: "linear" }, }}>      */}
              <motion.div 
              className="div-wp"
              initial={{ opacity: 0, translateX: -100 }}  
                 animate={{opacity: 1, translateX: 0}} 
                 transition={{  duration: 2, delay: 2.2,  ease:[0, 0.71, 0.2, 1.01] }}
              >
                      <WhatsAppIcon style={{ fontSize: '3.4rem'}}    className="icon-zap"/>
              </motion.div>
              <Footer />    
        </>
    )
}


export default Home;